import { Injectable } from '@angular/core';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbToastrService
} from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private toastrService: NbToastrService) {}

  showToast(
    type: NbComponentStatus,
    title: string,
    body: string,
    iconName: string = 'alert-triangle-outline'
  ) {
    const config = {
      status: type,
      destroyByClick: false,
      duration: 4000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: true,
      icon: iconName,
      pack: 'eva'
    };

    this.toastrService.show(body, title, config);
  }
}
