import { Injectable } from '@angular/core';
import { TapeValuation } from '../../../insurgo-interfaces/tape-value';
import { NbDialogService } from '@nebular/theme';
import { ViewSingleTapeAnalysisComponent } from './view-single-tape-analysis.component';


@Injectable()
export class ViewSingleTapeAnalysisService {

  constructor(
    private dialogService: NbDialogService
  ) { }

  show(_analysis: any) {

    const modal = this.dialogService.open(ViewSingleTapeAnalysisComponent, {
      closeOnBackdropClick: true,
      context: {
        tapeAnalysis: _analysis
      }
    });
    return new Promise<string>((resolve, reject) => {
      modal.onClose.subscribe((result) => {
        resolve(result);
      });
    });

  }
}
