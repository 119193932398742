import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { AddUserModalComponent } from './addUser.component';

@Injectable()
export class AddUserService {

    constructor(
      private dialogService: NbDialogService,
    ) {}

    addUserModal(_userID: number) {

        const modal = this.dialogService.open(AddUserModalComponent, {
          context: {
            userID: _userID,
          }
        });

        return new Promise((resolve, reject) => {
          modal.onClose.subscribe((result) => {
              resolve(result);
          });
        });
      }
}