import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoggerService } from './logging/logger.service';

@Injectable()
export class UserService {

  apiUrl = environment.apiUrl;

  constructor(
    public authHttp: HttpClient,
    private readonly logger: LoggerService
  ) { }

  getAllUsers(): Observable<any> {
    const URL = `${this.apiUrl}/userManagement`;

    return this.authHttp.get(URL)
      .pipe(
        catchError(this.handleError('getAllUsers', URL))
      );
  }

  getUserByID(userID: number): Observable<any> {
    const URL = `${this.apiUrl}/userManagement/${userID}`;

    return this.authHttp.get(URL)
      .pipe(
        catchError(this.handleError('getAllUsers', URL))
      );
  }


  addUser(_email: String, _firstName: String, _lastName: String, _type: number): Observable<any> {
    const URL = `${this.apiUrl}/userManagement`;
    const params = { email: _email, firstName: _firstName, lastName: _lastName, type: _type };

    return this.authHttp.post(URL, params)
      .pipe(
        catchError(this.handleError('addUser', URL))
      );
  }

  updateUser(userID: number, userData: any): Observable<any> {
    const URL = `${this.apiUrl}/userManagement/${userID}`;
    delete userData.name;
    delete userData.id;

    return this.authHttp.patch(URL, userData)
      .pipe(
        catchError(this.handleError('updateUser', URL))
      );
  }
  
  lockUser(_userID: number): Observable<any> {
    const URL = `${this.apiUrl}/userManagement/lock`;

    return this.authHttp.patch(URL, { userID: _userID, numberNewStatus: 1 })
      .pipe(
        catchError(this.handleError('lockUser', URL))
      );
  }

  unlockUser(_userID: number): Observable<any> {
    const URL = `${this.apiUrl}/userManagement/lock`;

    return this.authHttp.patch(URL, { userID: _userID, numberNewStatus: 0 })
      .pipe(
        catchError(this.handleError('unlockUser', URL))
      );
  }

  reInviteUser(userID: number): Observable<any> {
    const URL = `${this.apiUrl}/userManagement/re-invite/${userID}`;

    return this.authHttp.get(URL)
      .pipe(
        catchError(this.handleError('getAllUsers', URL))
      );
  }

  toggleGuide(guideValue: number): Observable<any> {
    const URL = `${this.apiUrl}/users/current/showGuide`;

    return this.authHttp.patch(URL, { showGuide: guideValue })
      .pipe(
        catchError(this.handleError('toggleGuide', URL))
      );
  }

  private handleError(operation: String, url: String): any {
    return (err: any) => {
      const errMsg = `Error in ${operation}() retrieving ${url}`;
      this.logger.error(`${errMsg}`);
      if (err instanceof HttpErrorResponse) {
        // you could extract more info about the error if you want, e.g.:
        this.logger.error(`status: ${err.status}, ${err.statusText}`);
      }

      return ErrorObservable.create(errMsg);
    };
  }
}