
export class ExineAgGrid {
  /* Public variables */
  columnDefs: any[];
  frameworkComponents;
  defaultColDef;
  context;
  gridOptions: any;
  columnApi;
  rowSelection;
  rowClassRules;
  gridApi;
  paginationPageSize;
  paginationNumberFormatter;
  desiredPaginationSize: number = 10;
  domLayout;
  rowData: any[] = [];


  constructor(

  ) {

    // set default grid settings
    this.gridOptions = {
      onCellDoubleClicked: undefined,
      onCellClicked: undefined,
      tooltipShowDelay: 500,
      onCellValueChanged: undefined,
      tooltipMouseTrack: true

    }

    this.domLayout = 'autoHeight';

    this.paginationPageSize = 10;

    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      filter: true,
      resizable: true,
      headerComponentParams: {
        template:
          `<div class="ag-cell-label-container" role="presentation">
        <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
        <div ref="eLabel" class="ag-header-cell-label" role="presentation">
          <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
          <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
          <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>
          <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
        </div>
      </div>`,
      }
      // tooltipComponent: 'customTooltip',
    };
    this.paginationNumberFormatter = function (params) {
      return params.value.toLocaleString();
    };
  }

  onPageSizeChanged() {
    this.gridApi.paginationSetPageSize(this.desiredPaginationSize);
  }

  csvExport(fileName) {
    this.gridApi.exportDataAsCsv({ fileName: fileName });
  }

  headerHeightGetter() {
    var columnHeaderTexts = document.querySelectorAll('.ag-header-cell-text');
    var columnHeaderTextsArray = [];

    columnHeaderTexts.forEach(node => columnHeaderTextsArray.push(node));

    var clientHeights = columnHeaderTextsArray.map(
      headerText => headerText.clientHeight
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);
    return tallestHeaderTextHeight;
  }
  headerHeightSetter() {
    var padding = 20;
    var height = this.headerHeightGetter() + padding;
    this.gridApi.setHeaderHeight(height);
    this.gridApi.resetRowHeights();
  }


}