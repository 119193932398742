import { Component, Input, OnInit, Output, EventEmitter, forwardRef } from '@angular/core';
import { AddressService } from '../../insurgo-services/address.service';
import { PostalAddress } from '../../insurgo-interfaces/address';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, FormControl, NumberValueAccessor } from '@angular/forms';
import { JobType } from '../../insurgo-interfaces/kit';
import { ConfigurationService } from '../../insurgo-services/configuration.service';

@Component({
  selector: 'app-job-type-selector',
  styles: [],
  template: `<ng-select
                  [(ngModel)]  ='_selectedData'
                  [items] = "loadedData"
                  (change) = "change($event)"
                  (open) = "touch()"
                  placeholder="Select job type..."
                  bindLabel = "jobType"
                  bindValue = "jobTypeID"
                  notFoundText = ""
                  [disabled] = "_disabled"                  
                  appendTo="body"
                  [ngClass]="{ 'is-dirty': control?.dirty }"
              >
              </ng-select>
        `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JobTypeSelectorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => JobTypeSelectorComponent),
      multi: true
    }
  ],
})

export class JobTypeSelectorComponent implements OnInit, ControlValueAccessor, Validator {
  constructor(
    private configurationService: ConfigurationService
  ) { }

  @Input() required = false; // default required as false 
  @Input() control: FormControl;
  @Input() showHidden = false;
  @Output() private onChange = new EventEmitter();

  _selectedData: number;
  _invalid = true; // always invalid at the start
  _disabled = false;
  loadedData: { jobType: string, jobTypeID: number }[] = [];
  propagateChange = (_: any) => { };
  propagateTouch = (_: any) => { };

  touch() {
    this.propagateTouch(null);
  }

  get runChangeDetection() {
    console.log('Checking the view');
    return true;
  }

  validate(c: FormControl) {
    if (this.required) {
      return (!this._invalid) ? null : { required: { valid: false } };
    } else {
      return null;
    }
  }

  writeValue(value: any) {
    this._selectedData = value;
    if (!value) {
      this._invalid = true;
    } else {
      this._invalid = false;
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  ngOnInit(): any {
    this.getAllData();
  }

  change(_newData): void {
    if (!_newData) {
      // this._selectedData = null;
      this._invalid = true;
      this.propagateChange(null);
      this.onChange.emit(null);
    } else {
      // this._selectedData = _newData;
      this._invalid = false;
      this.propagateChange(this._selectedData);
      this.onChange.emit(this._selectedData);
    }
  }

  private getAllData(): void {
    // reset view for component refresh
    this.loadedData = [{
      jobType: "On-Site Refurbishment / Destruction",
      jobTypeID: JobType.STANDARD
    }]

    if (this.configurationService.getConfigurationSettings().auditAllowed) {
      this.loadedData.push({
        jobType: "Tape Audit / Full CM Inspection",
        jobTypeID: JobType.TAPE_AUDIT
      })
    }
    if (this.configurationService.getConfigurationSettings().sendAllAllowed) {
      this.loadedData.push({
        jobType: "Insurgo Hub Enhanced Destruction Confirmation Scan",
        jobTypeID: JobType.SEND_ALL_TAPES
      })
    }

  }
}
