import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-confirmation-modal',
  template: `
              <nb-card>
                <nb-card-header>
                  {{title}}
                </nb-card-header>
                <nb-card-body>
                  {{bodyMessage}}
                </nb-card-body>
                <nb-card-footer class="clear-fix">
                  <button *ngIf="confirmButtonText.length > 0" nbButton outline status="success" class="float-right" (click)="confirm()">{{confirmButtonText}}</button>
                  <button *ngIf="cancelButtonText.length > 0" nbButton outline status="danger" class="float-left" (click)="decline()">{{cancelButtonText}}</button>
                </nb-card-footer>
              </nb-card>
            `  
})


export class ModalConfirmationComponent {
  @Input() title: string;
  @Input() bodyMessage: string;
  @Input() confirmButtonText: string;
  @Input() cancelButtonText: string;
  constructor(protected ref: NbDialogRef<ModalConfirmationComponent>) { }

  confirm(): void {
    this.ref.close('confirm');
  }

  decline(): void {
    this.ref.close('decline');
  }

  cancel(): void {
    this.ref.close('cancel');
  }
}