import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'process-bar',
  template:

    `
  
<div class="progress py-1" style="width: 100%; height:100%" [nbTooltip]="tooltip" nbTooltipPlacement="left">
  <div class="progress-bar" style='background-color: #495867' role="progressbar" [attr.aria-valuenow]="pg1" aria-valuemin="0" aria-valuemax="100"
    [ngStyle]="{'width' : pg1w }">
    {{pg1v}}
  </div>
  <div class="progress-bar" style='background-color: #577399' role="progressbar" [attr.aria-valuenow]="pg2" aria-valuemin="0"
    aria-valuemax="100" [ngStyle]="{'width' : pg2w }">
    {{pg2v}}
  </div>
  <div class="progress-bar" style='background-color: #F7F7FF; color:black' role="progressbar" [attr.aria-valuenow]="pg3" aria-valuemin="0"
    aria-valuemax="100" [ngStyle]="{'width' :pg3w}">
    {{pg3v}}
  </div>
  <div class="progress-bar" style='background-color: #FE5F55' role="progressbar" [attr.aria-valuenow]="pg4" aria-valuemin="0"
    aria-valuemax="100" [ngStyle]="{'width' :pg4w }">
    {{pg4v}}
  </div>

</div>

`
})
export class ProgressBarComponent implements ICellRendererAngularComp {
  params: any;

  pg1: number = 0;
  pg1w: string = '0%';
  pg1v: number = 0;
  pg2: number = 0;
  pg2w: string = '0%';
  pg2v: number = 0;
  pg3: number = 0;
  pg3w: string = '0%';
  pg3v: number = 0;
  pg4: number = 0;
  pg4w: string = '0%';
  pg4v: number = 0;
  tooltip: string = '';
  agInit(params: any): void {
    this.params = params;
    //params.eGridCell.innerHTML = params.eGridCell.innerHTML;
    //console.log(`save me - ${params.eGridCell.innerHTML}`);
    let atPercentage = (100 / params.data.numberTapes) * params.data.numberTapesAT;
    if (atPercentage) this.pg1 = Math.round(atPercentage);
    let erPercentage = (100 / params.data.numberTapes) * params.data.numberTapesER;
    if (erPercentage) this.pg2 = Math.round(erPercentage)
    let compPercentage = (100 / params.data.numberTapes) * params.data.numberTapesScannedOut;
    if (compPercentage) this.pg3 = Math.round(compPercentage)
    let dePercentage = (100 / params.data.numberTapes) * params.data.numberTapesDE;
    if (dePercentage) this.pg4 = Math.round(dePercentage)

    
    this.pg1w = `${this.pg1}%`;
    this.pg1v = params.data.numberTapesAT;
    this.pg2w = `${this.pg2}%`;
    this.pg2v = params.data.numberTapesER;
    this.pg3w = `${this.pg3}%`;
    this.pg3v = params.data.numberTapesScannedOut;
    this.pg4w = `${this.pg4}%`;
    this.pg4v = params.data.numberTapesDE;

    this.tooltip=`Asset Tracked: ${this.pg1v}, Erased: ${this.pg2v}, Scanned Out: ${this.pg3v}, Destroyed: ${this.pg4v}`

    
    //console.log(`these damn params - ${this.pg1}, ${this.pg2}, ${this.pg3}, ${this.pg4}`)
  }

  refresh(): boolean {
    return false;
  }

  constructor() { }

}