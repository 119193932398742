<div class="row">
  <form #editAddressForm="ngForm">
    <nb-card accent="info" *ngIf=loaded style="max-height: 50.25rem;">
      <nb-card-header class="text-center clear-fix">
        <button nbButton outline size="tiny" status="info" class="float-right" (click)=cancel(editAddressForm.form.dirty)>x</button>
        <br>
        <H5 style="color:orange;">{{companyName}}</H5>
        <div *ngIf="addressID">
          <div class="item text-hint"> Address Edit</div>
        </div>
        <div *ngIf="!addressID">
          <div class="item text-hint">New Address</div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group mb-0">                      
              <label for="line1Input" class="label" required>Line 1</label>
              <input type="text" nbInput fullWidth required [status]="displayFieldCss(editAddressForm, line1Input)" maxlength="100" id="line1Input" name="line1Input" [(ngModel)]="address.line1" #line1Input="ngModel" fieldSize="small">   
              <app-form-field-error [displayError]="line1Input.touched && line1Input.invalid" [displaySuccess]="line1Input.valid" errorMsg="Line 1 is required"></app-form-field-error>
            </div>
            <div class="form-group mb-0">                      
              <label for="line2Input" class="label">Line 2</label>
              <input type="text" nbInput fullWidth [status]="displayFieldCss(editAddressForm, line2Input)" maxlength="100" id="line2Input" name="line2Input" [(ngModel)]="address.line2" #line2Input="ngModel" fieldSize="small">   
              <app-form-field-error [displayError]="line2Input.touched && line2Input.invalid" [displaySuccess]="line2Input.valid" errorMsg="Max. length 100 chars"></app-form-field-error>
            </div>
            <div class="form-group mb-0">                      
              <label for="cityInput" class="label">Town/City</label>
              <input type="text" nbInput fullWidth required [status]="displayFieldCss(editAddressForm, cityInput)" maxlength="50" id="cityInput" name="cityInput" [(ngModel)]="address.city" #cityInput="ngModel" fieldSize="small">      
              <app-form-field-error [displayError]="cityInput.touched && cityInput.invalid" [displaySuccess]="cityInput.valid" errorMsg="Town/City is requried"></app-form-field-error>
            </div>
            <div class="form-group mb-0" [hidden]="address.country !== 'United Kingdom'">                      
              <label for="countyInput" class="label">County</label>
              <app-county-selector [(ngModel)]="address.county" id="countryInput" [name]='"countyInput"' [required]="address.country === 'United Kingdom'" #countySelectInput='ngModel'></app-county-selector>
              <app-form-field-error [displayError]="countySelectInput.touched && countySelectInput.invalid" [displaySuccess]="countySelectInput.valid" errorMsg="County is required"></app-form-field-error>
            </div>
            <div class="form-group mb-0" [hidden]="address.country === 'United Kingdom'">                      
              <label for="countyInput" class="label">County</label>
              <input type="text" nbInput fullWidth [status]="displayFieldCss(editAddressForm, countyTextInput)" required="address.country !== 'United Kingdom'" maxlength="35" id="countyInput" name="countyInput" [(ngModel)]="address.county" #countyTextInput="ngModel" fieldSize="small">                           
              <app-form-field-error [displayError]="countyTextInput.touched && countyTextInput.invalid" [displaySuccess]="countyTextInput.valid" errorMsg="County is required (max: 35)"></app-form-field-error>
            </div>
            <div class="form-group mb-0">                      
              <label for="countryInput" class="label"> Country</label>
              <app-country-selector [(ngModel)]="address.country" id="countryInput" [name]='"countryInput"' [required]=true #countryInput='ngModel' fieldSize="small"></app-country-selector>
              <app-form-field-error [displayError]="countryInput.touched && countryInput.invalid" [displaySuccess]="countryInput.valid" errorMsg="Country is required"></app-form-field-error>
            </div>
            <div class="form-group mb-0">                      
              <label for = "postCodeInput" class="label">Post Code / ZIP</label>
              <input type="text" nbInput fullWidth required [status]="displayFieldCss(editAddressForm, postCodeInput)" maxlength="35" id="postCodeInput" name="postCodeInput" [(ngModel)]="address.postalCode" #postCodeInput="ngModel" fieldSize="small">                            
              <app-form-field-error [displayError]="postCodeInput.touched && postCodeInput.invalid" [displaySuccess]="postCodeInput.valid" errorMsg="Postal Code / Zip is required (max: 35)"></app-form-field-error>
            </div>
            <div class="form-group mb-0">                      
              <label for="telephoneInput" class="label">Contact Telephone Number</label>
              <input type="text" nbInput fullWidth required [status]="displayFieldCss(editAddressForm, telephoneInput)" minlength="8" maxlength="20" id="telephoneInput" name="telephoneInput" [(ngModel)]="address.phoneNumber" #telephoneInput="ngModel" fieldSize="small">                            
              <app-form-field-error [displayError]="telephoneInput.touched && telephoneInput.invalid" [displaySuccess]="telephoneInput.valid" errorMsg="Telephone number is required (Min: 8, Max 20)"></app-form-field-error>
            </div>
          </div>
        </div>
      </nb-card-body>
      <nb-card-footer class="clear-fix">
        <div class="alert alert-danger" *ngIf='formError.error'>Saving address failed...</div>              
        <div class="alert alert-success" *ngIf='formError.success'>Address saved successfully!</div>
        <button nbButton outline status="success" [disabled]="editAddressForm.invalid || formError.submitting || !editAddressForm.dirty" type="submit" value="submit" (click)="submit()" class="float-right">Save</button>
        <button nbButton outline status="danger" *ngIf="editAddressForm.dirty || editAddressForm.dirty && !formError.submitting" class="float-left" (click)=reset(editAddressForm)>Cancel</button>
      </nb-card-footer>
    </nb-card>
  </form>
</div>
