<div class="row">
  <form #editCompanyForm="ngForm">
    <nb-card accent="info" size='giant' *ngIf=loaded style="max-height: 50.25rem;">
      <nb-card-header class="text-center clear-fix">
        <button nbButton outline size="tiny" status="info" class="float-right" (click)=cancel(editCompanyForm.form.dirty)>x</button>
        <div *ngIf="companyID">
          <H5>
            <span style="color:orange;">{{originalData?.companyName}}</span>
            <a *ngIf="originalData.website" target="_blank" href="{{originalData.website | httpify}}" >
              <i><nb-icon icon="globe-2"></nb-icon></i></a>
          </H5>
          <div class="item text-hint"> {{originalData.address.addressString}}</div>
        </div>
        <div *ngIf="!companyID">
          <h4 class="modal-title" style="color:orange;">New Client Form</h4>
        </div>
      </nb-card-header>
      <nb-card-body class="pl-0 pr-0 pb-0">
      <nb-tabset (changeTab)="onChangeTab($event)">
          <nb-tab tabTitle="General">          
            <div class="row">
              <div class="col-md-6">
                <H5 class="text-center mb-0 mt-0">Client</H5>
                  <div class="form-group mb-0">                      
                    <label for="companyName" class="label">Company Name</label>
                    <input type="text" nbInput fullWidth [status]="displayFieldCss(editCompanyForm, companyNameInput)" id="companyName" name="companyName" required minlength="3" maxlength="35" [(ngModel)]="viewCompanyData.companyName" #companyNameInput="ngModel" fieldSize="small">
                    <app-form-field-error [displayError]="companyNameInput.touched && companyNameInput.invalid" [displaySuccess]="companyNameInput.valid" errorMsg="Invalid company name (Min: 3, Max: 35)"></app-form-field-error>
                  </div>
                  <div class="form-group mb-0">                      
                    <label for="contactName" class="label">Contact Name</label>
                    <input type="text" nbInput fullWidth [status]="displayFieldCss(editCompanyForm, contactNameInput)" id="contactName" name="contactName" required maxlength="35" [(ngModel)]="viewCompanyData.contactName" #contactNameInput="ngModel" fieldSize="small">                            
                    <app-form-field-error [displayError]="contactNameInput.touched && contactNameInput.invalid" [displaySuccess]="contactNameInput.valid" errorMsg="Contact name is required"></app-form-field-error>
                  </div>
                  <div class="form-group mb-0">                      
                    <label for="contactEmailInput" class="label">Contact Email</label>
                    <input type="email" nbInput fullWidth email required [status]="displayFieldCss(editCompanyForm, contactEmailInput)" id="contactEmailInput" name="contactEmailInput" [(ngModel)]="viewCompanyData.email" #contactEmailInput="ngModel" fieldSize="small">                            
                    <app-form-field-error [displayError]="contactEmailInput.touched && contactEmailInput.invalid" [displaySuccess]="contactEmailInput.valid" errorMsg="Email is required or invalid"></app-form-field-error>
                  </div>
                  <div class="form-group mb-0">                      
                    <label for="telephoneInput" class="label">Contact Telephone Number</label>
                    <input type="text" nbInput fullWidth required [status]="displayFieldCss(editCompanyForm, telephoneInput)" minlength="8" maxlength="20" id="telephoneInput" name="telephoneInput" [(ngModel)]="viewCompanyData.phoneNumber" #telephoneInput="ngModel" fieldSize="small">                            
                    <app-form-field-error [displayError]="telephoneInput.touched && telephoneInput.invalid" [displaySuccess]="telephoneInput.valid" errorMsg="Telephone number is required (Min: 8, Max 20)"></app-form-field-error>
                  </div>
                  <div class="form-group mb-0">                      
                    <label for="websiteInput" class="label">Website</label>
                    <input type="text" nbInput fullWidth [status]="displayFieldCss(editCompanyForm, websiteInput)" id="websiteInput" name="websiteInput" [(ngModel)]="viewCompanyData.website" #websiteInput="ngModel" fieldSize="small">                            
                    <app-form-field-error [displayError]="websiteInput.touched && websiteInput.invalid" [displaySuccess]="websiteInput.valid" errorMsg="Website is invalid"></app-form-field-error>
                  </div>
              </div>
              <div class="col-md-6">
                <H5 class="text-center mb-0 mt-0">Main Address</H5>
                <div class="form-group mb-0">                      
                  <label for="line1Input" class="label" required>Line 1</label>
                  <input type="text" nbInput fullWidth required [status]="displayFieldCss(editCompanyForm, line1Input)" maxlength="100" id="line1Input" name="line1Input" [(ngModel)]="viewCompanyData.address.line1" #line1Input="ngModel" fieldSize="small">   
                  <app-form-field-error [displayError]="line1Input.touched && line1Input.invalid" [displaySuccess]="line1Input.valid" errorMsg="Line 1 is required"></app-form-field-error>
                </div>
                <div class="form-group mb-0">                      
                  <label for="line2Input" class="label">Line 2</label>
                  <input type="text" nbInput fullWidth [status]="displayFieldCss(editCompanyForm, line2Input)" maxlength="100" id="line2Input" name="line2Input" [(ngModel)]="viewCompanyData.address.line2" #line2Input="ngModel" fieldSize="small">   
                  <app-form-field-error [displayError]="line2Input.touched && line2Input.invalid" [displaySuccess]="line2Input.valid" errorMsg="Max. length 100 chars"></app-form-field-error>
                </div>
                <div class="form-group mb-0">                      
                  <label for="cityInput" class="label">Town/City</label>
                  <input type="text" nbInput fullWidth required [status]="displayFieldCss(editCompanyForm, cityInput)" maxlength="50" id="cityInput" name="cityInput" [(ngModel)]="viewCompanyData.address.city" #cityInput="ngModel" fieldSize="small">      
                  <app-form-field-error [displayError]="cityInput.touched && cityInput.invalid" [displaySuccess]="cityInput.valid" errorMsg="Town/City is requried"></app-form-field-error>
                </div>
                <div class="form-group mb-0" [hidden]="viewCompanyData.address.country !== 'United Kingdom'">                      
                  <label for="countyInput" class="label">County</label>
                  <app-county-selector [(ngModel)]="viewCompanyData.address.county" id="countryInput" [name]='"countyInput"' [required]="viewCompanyData.address.country === 'United Kingdom'" #countySelectInput='ngModel'></app-county-selector>
                  <app-form-field-error [displayError]="countySelectInput.touched && countySelectInput.invalid" [displaySuccess]="countySelectInput.valid" errorMsg="County is required"></app-form-field-error>
                </div>
                <div class="form-group mb-0" [hidden]="viewCompanyData.address.country === 'United Kingdom'">                      
                  <label for="countyInput" class="label">County</label>
                  <input type="text" nbInput fullWidth [status]="displayFieldCss(editCompanyForm, countyTextInput)" required="viewCompanyData.address.country !== 'United Kingdom'" maxlength="35" id="countyInput" name="countyInput" [(ngModel)]="viewCompanyData.address.county" #countyTextInput="ngModel" fieldSize="small">                           
                  <app-form-field-error [displayError]="countyTextInput.touched && countyTextInput.invalid" [displaySuccess]="countyTextInput.valid" errorMsg="County is required (max: 35)"></app-form-field-error>
                </div>
                <div class="form-group mb-0">                      
                  <label for="countryInput" class="label"> Country</label>
                  <app-country-selector [(ngModel)]="viewCompanyData.address.country" id="countryInput" [name]='"countryInput"' [required]=true #countryInput='ngModel' fieldSize="small"></app-country-selector>
                  <app-form-field-error [displayError]="countryInput.touched && countryInput.invalid" [displaySuccess]="countryInput.valid" errorMsg="Country is required"></app-form-field-error>
                </div>
                <div class="form-group mb-0">                      
                  <label for = "postCodeInput" class="label">Post Code / ZIP</label>
                  <input type="text" nbInput fullWidth required [status]="displayFieldCss(editCompanyForm, postCodeInput)" maxlength="35" id="postCodeInput" name="postCodeInput" [(ngModel)]="viewCompanyData.address.postalCode" #postCodeInput="ngModel" fieldSize="small">                            
                  <app-form-field-error [displayError]="postCodeInput.touched && postCodeInput.invalid" [displaySuccess]="postCodeInput.valid" errorMsg="Postal Code / Zip is required (max: 35)"></app-form-field-error>
                </div>
              </div>
            </div>
          </nb-tab>
          <nb-tab *ngIf="companyID" tabTitle="Addresses" class="pl-0 pr-0">
            <div class="text-center ml-0 mr-0 mb-0" >
              <button nbButton size="small" class="mb-2" status="info" (click)=addAddress()><nb-icon icon="plus-circle-outline"></nb-icon> Add New Address</button>
              <ag-grid-angular
                #agGrid
                style="height: 380px;"
                class="ag-theme-alpine ml-0 mr-0 mb-0"
                [rowData]="addresses"
                [columnDefs]="columnDefs"
                [context]="context"
                [frameworkComponents]="frameworkComponents"
                [defaultColDef]="defaultColDef"
                [gridOptions]="gridOptions"
                [enableCellTextSelection]="true"
                >
              </ag-grid-angular>
            </div>
          </nb-tab>
        </nb-tabset>
      </nb-card-body>
      <nb-card-footer class="clear-fix">
        <div class="alert alert-danger" *ngIf='formError.error'>Saving client failed...</div>              
        <div class="alert alert-success" *ngIf='formError.success'>Client saved successfully!</div>
        <button nbButton outline status="success" *ngIf="tabState === 'General'" [disabled]="editCompanyForm.invalid || formError.submitting || !editCompanyForm.dirty" type="submit" value="submit" (click)="submit()" class="float-right">Save</button>
        <button nbButton outline status="danger" *ngIf="tabState === 'General' && (editCompanyForm.dirty || editCompanyForm.dirty && !formError.submitting)" class="float-left" (click)=reset(editCompanyForm)>Cancel</button>
      </nb-card-footer>
    </nb-card>
  </form>
</div>
