import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LoggerService } from './logging/logger.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { Scanner } from '../insurgo-interfaces/scanner'
import { ProcessTypes, ProcessTypesString } from '../insurgo-interfaces/process';


export interface scannerSelectionOptions {
    barcode: boolean,
    usb: boolean,
    // wifi: boolean
}

@Injectable()
export class ScannerHelperService {

    apiUrl = environment.apiUrl;

    constructor(
        public authHttp: HttpClient,
        private readonly logger: LoggerService
    ) { }

    getAllScanners(options: scannerSelectionOptions): Observable<Scanner[]> {
        const URL = `${this.apiUrl}/facility/scanner`;

        return this.authHttp.get(URL)
            .pipe(
                map((result: Scanner[]) => {
                    let acceptableScanners: Scanner[] = [];

                    for (let index = result.length - 1; index >= 0; index--) {

                        if (result[index].defaultProcessType === ProcessTypes.HKD_Degaussed) {
                            result[index].defaultProcessTypeString = ProcessTypesString.HKD_Degaussed;

                        } else if (result[index].defaultProcessType === ProcessTypes.HKD_SWAT) {
                            result[index].defaultProcessTypeString = ProcessTypesString.HKD_SWAT;
                        }


                        if (options.barcode && result[index].scannerType === 2) {
                            acceptableScanners.push(result[index]);
                        }
                       
                        if (options.usb && result[index].scannerType === 3) {
                            acceptableScanners.push(result[index]);
                        }


                    }



                    return acceptableScanners;
                }),
                catchError(this.handleError('getAllScanners', URL))
            );
    }

    editScanner(scannerData: Scanner) {
        const URL = `${this.apiUrl}/facility/scanner/${scannerData.scannerID}`;

        return this.authHttp.patch(URL, <object>scannerData)
            .pipe(
                catchError(this.handleError('editScanner', URL))
            );
    }


    acceptableFirmware(minRequirement: { major: number, minor: number, revision: number }, current: string): boolean{
        let acceptable = false;
        if(!current || current.length < 6){
            return acceptable;
        }

        const currentFirmware = this.extractFirmwareVersion(current);

        var comparisonResult = this.compareVersions(currentFirmware, minRequirement);

        if (comparisonResult < 0) {
            console.log("Current version is less than the minimum requirement.");
        } else if (comparisonResult > 0) {
            console.log("Current version is greater than the minimum requirement.");
            acceptable = true;
        } else {
            console.log("Current version is exactly the minimum requirement.");
            acceptable = true;
        }
        return acceptable
    }

    private compareVersions(versionA: { major: number, minor: number, revision: number }, versionB: { major: number, minor: number, revision: number }): number {
        if (versionA.major !== versionB.major) {
            return versionA.major - versionB.major;
        } else if (versionA.minor !== versionB.minor) {
            return versionA.minor - versionB.minor;
        } else if (versionA.revision !== versionB.revision) {
            return versionA.revision - versionB.revision;
        } else {
            return 0;
        }
    }
    private extractFirmwareVersion(firmware: string): { major: number, minor: number, revision: number } {
        // Split the string by '-'. This will separate the version numbers from the suffix (e.g., 'PROD').
        var splitString = firmware.split('-')[0];

        // Split the version numbers by '.'. This will separate the major, minor, and revision numbers.
        var versionNumbers = splitString.split('.');

        // Parse the version numbers as integers.
        var major = parseInt(versionNumbers[0], 10);
        var minor = parseInt(versionNumbers[1], 10);
        var revision = parseInt(versionNumbers[2], 10);

        // Return an object with the version numbers.
        return {
            major: major,
            minor: minor,
            revision: revision
        };
    }

    private handleError(operation: String, url: String): any {
        return (err: any) => {
            const errMsg = `Error in ${operation}() retrieving ${url}`;
            this.logger.error(`${errMsg}`);
            if (err instanceof HttpErrorResponse) {
                // you could extract more info about the error if you want, e.g.:
                this.logger.error(`status: ${err.status}, ${err.statusText}`);
            }

            return ErrorObservable.create(errMsg);
        };
    }
}
