import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { CreateKitComponent } from './create-kit/create-kit.component';
import { ClientsComponent } from './clients/clients.component';
import { DrivesComponent } from './drives/drives.component';
import { TapeValueComponent } from './tape-value/tape-value.component';


const routes: Routes = [
  {
    path: 'users',
    component: UsersComponent,
  },
  {
    path: 'create-job',
    component: CreateKitComponent,
  },  
  {
    path: 'clients',
    component: ClientsComponent,
  },
  {
    path: 'drives',
    component: DrivesComponent,
  },
  {
    path: 'tape-value',
    component: TapeValueComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManagementRoutingModule { }
