<img src="assets/images/logo.png" class="img-fluid" alt={{altText}}>

<br>
<br>

<nb-alert outline="success" role="alert">
  <p class="alert-title"><b>Logged Out!</b></p>
  <ul class="alert-message-list">
    <li class="alert-message">You have been logged out, redirecting...</li>
  </ul>
</nb-alert>