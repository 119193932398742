import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NbDialogRef, NB_THEME_OPTIONS } from '@nebular/theme';

import { ModalConfirmationService } from '../confirmation-modal/confirmation-modal.service';
import { UserService } from '../../user.service'
import { NgForm } from '@angular/forms';

import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from '@nebular/theme';


@Component({
  templateUrl: './addUser.html',
  styles:[
    `::ng-deep .ng-dropdown-panel.inModal {
      z-index: 1060 !important;
    }`
  ],
})

export class AddUserModalComponent implements OnInit {
  @Input() userID;

  originalData: Object;
  loaded = false;
  user = {
    email:'',
    firstName:'',
    lastName: '',
    userRole: 1, //default to standard user,
    name:''
  };

  formError: any = {
    submitting: false,
    error: false,
    success: false
  };

  userRoles = [
    {
      id:1,
      description:'Standard User'
    }, 
    {
      id:9,
      description: 'Admin'
    }
  ]

  @ViewChild('addUserForm') addUserForm: NgForm;

  constructor(
    protected ref: NbDialogRef<AddUserModalComponent>,
    private _modalConfirmationService: ModalConfirmationService,
    private _userService: UserService,
    private toastrService: NbToastrService
  ) { }

  async ngOnInit() {
    this.originalData = this.deepCopy(<Object>this.user);
    if(this.userID){
      await this.getData()
    } else {
      this.loaded = true;
    }
  }
  async getData(){
    try {
      this.loaded = false;
      let data = <any> await this._userService.getUserByID(this.userID).toPromise();
      data.name = `${data.firstName} ${data.lastName}`;
      this.user = this.deepCopy(data);
      this.originalData = this.deepCopy(data);
      this.loaded = true;
    } catch (error) {
      this.showToast("danger","Fetch Error", `Error occurred getting the user data!`, 'activity');
      this.ref.close(false);
    }
  }

  submit(): void {
    // set some realistic defaults
    this.formError.submitting = true;
    this.formError.success = false;
    this.formError.error = false;
    if(this.userID){
      this._userService.updateUser(this.userID, this.user).subscribe(
        (response) => {
          this.formError.success = true;
          setTimeout(function() {
            this.showToast("success", "User Edited", `${this.user.firstName} was edited successfully!`, 'checkmark-outline')
            this.ref.close(true);
          }.bind(this), 3000);
        }, 
        (err) => {
          console.log(err);
          this.formError.error = true;
          setTimeout(function() {
            this.formError.submitting = false;
          }.bind(this), 3000);
        }
      )
    } else {
      this._userService.addUser(this.user.email, this.user.firstName, this.user.lastName, this.user.userRole).subscribe(
        (response) => {
          this.formError.success = true;
          setTimeout(function() {
            this.showToast("success", "User Added", `A new user successfully added, they will receive an invite shortly!`, 'checkmark-outline')
            this.ref.close(true);
          }.bind(this), 3000);
        }, 
        (err) => {
          console.log(err);
          this.formError.error = true;
          setTimeout(function() {
            this.formError.submitting = false;
          }.bind(this), 3000);
        }
      )
    }
  }

  async resetForm(){
    await this.getData();
    this._markFormPristine();
  }

  private _markFormPristine(): void {
    const { addUserForm: { value: formValueSnap } } = this; // const formValueSnap = this.myForm.value;
    this.addUserForm.reset(formValueSnap);
    Object.keys(this.addUserForm.controls).forEach(control => {
      this.addUserForm.controls[control].markAsPristine();
      this.addUserForm.controls[control].markAsUntouched();
    });
  }

  reset(form:NgForm){
    this.user = this.deepCopy(<Object>this.originalData);
    this.formError.submitting = false;
    this.formError.success = false;
    setTimeout(function() {
            this._markFormPristine();
    }.bind(this), 500);
  }

  async cancel(dirty) {
    if (dirty) {
      const result =  await this._modalConfirmationService.confirm('Still Editing the form?', 'You have not finished editing, are you sure you want to cancel?');
      if (result === 'confirm') {
        this.ref.close(false);
      } else if (result === 'cancel') {

      } else if (result === 'decline') {

      }
    } else {
      this.ref.close(false);
    }
  }

  private showToast(type: NbComponentStatus, title: string, body: string, iconName: string = 'alert-triangle-outline') {
    const config = {
      status: type,
      destroyByClick: false,
      duration: 4000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: true,
      icon: iconName,
      pack: 'eva' 
    };

    this.toastrService.show(
      body,
      title,
      config
    );
  }

  displayFieldCss(form: NgForm, field: any) {
    if (form.dirty) {
      if (field.touched && field.invalid) {
        //invalid
        return 'danger';
      } else if (field.valid) {
        //valid
        return 'success';
      } else {
        //needs to be filled in
        return 'warning';
      }
    } else {
      return 'basic';
    }
  }

  deepCopy(obj): any {
    let copy;
    // Handle the 3 simple types, and null or undefined
    if (obj == undefined || typeof obj !== 'object') { return obj; }
    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());

        return copy;
    }
    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.deepCopy(obj[i]);
        }

        return copy;
    }
    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) { copy[attr] = this.deepCopy(<Object>obj[attr]); }
        }

        return copy;
    }
    throw new Error(`Unable to copy obj! Its type isn't supported.`);
  }
}

