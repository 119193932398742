import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { AuthModule } from './@auth/auth.module';
import { InitUserService } from './@theme/services/init-user.service';

import { ConsoleLoggerService } from './insurgo-services/logging/console-logger.service';
import { LoggerService } from './insurgo-services/logging/logger.service';

import { AddressService } from './insurgo-services/address.service';
import { CompanyService } from './insurgo-services/company.service';
import { UserService } from './insurgo-services/user.service';

import { AgGridModule } from 'ag-grid-angular';
import { ButtonRendererComponent } from './insurgo-services/cellRenderers/button.renderer.component';

import { ModalServiceModule } from './insurgo-services/modalTemplates/modal-service.module';
import { ManagementModule } from './insurgo-pages/management/management.module';

import { MainPipeModule } from './insurgo-ui-components/pipes/pipes-module'

import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  NbIconModule,
  NbButtonModule,
  NbTooltipModule
} from '@nebular/theme';

import { NbEvaIconsModule } from '@nebular/eva-icons';
import { ScannerHelperService } from './insurgo-services/scanner-helper.service';

import { Angulartics2Module } from 'angulartics2';
import { ConfigurationService } from './insurgo-services/configuration.service';

export function init_app(injector: Injector) {
  return () =>
    new Promise<any>((resolve: Function) => {
        const initUserService = injector.get(InitUserService);
        initUserService.initCurrentUser().subscribe(() => {

        },
          () => resolve(), () => resolve()); // a place for logging error
    });
}

@NgModule({
  declarations: [
    AppComponent,
    ButtonRendererComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,    
    AuthModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbButtonModule,
    NbTooltipModule,
    NbIconModule,
    NbEvaIconsModule,
    AgGridModule.withComponents([ButtonRendererComponent]),
    MainPipeModule,
    CoreModule.forRoot(),
    ModalServiceModule,
    ManagementModule,
    ThemeModule.forRoot(),
    Angulartics2Module.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigurationService) =>
          () => configService.setConfigurationSettings(),
      deps: [ConfigurationService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [Injector],
      multi: true,
    },
    {
      provide: LoggerService,
      useClass: ConsoleLoggerService
    },
    AddressService,
    CompanyService,
    UserService,
    ScannerHelperService 
  ],
})
export class AppModule {
}
