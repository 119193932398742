<nb-card style="min-width: 75vw;">
  <nb-card-header>
    Manifest Reconcile Results
  </nb-card-header>
  <nb-card-body style="overflow-y: scroll">
    The below errors will mean you are unable to mark this job as complete
    <div class="d-flex justify-content-between">
      <div class="p-2">
        Page Size:
        <select [(ngModel)]="reconcileTable.desiredPaginationSize" (change)="reconcileTable.onPageSizeChanged()">
          <option value=10 selected="">10</option>
          <option value=100>100</option>
          <option value=500>500</option>
          <option value=1000>1000</option>
        </select>
      </div>
      <div class="p-2">

        <button nbButton status="success" size="small" (click)="csvExport()" class="mx-3"
          [disabled]="reconcileTable.rowData.length === 0">CSV
          Export
          <nb-icon icon="external-link-outline"></nb-icon>
        </button>
      </div>
    </div>
    <ag-grid-angular #agGrid style="width: 100%;" class="ag-theme-alpine" [rowData]="reconcileTable.rowData"
      [columnDefs]="reconcileTable.columnDefs" [context]="reconcileTable.context"
      [frameworkComponents]="reconcileTable.frameworkComponents" [defaultColDef]="reconcileTable.defaultColDef"
      [gridOptions]="reconcileTable.gridOptions" [enableCellTextSelection]="true"
      [rowSelection]="reconcileTable.rowSelection" [rowClassRules]="reconcileTable.rowClassRules"
      (gridReady)="onGridReady($event)" (firstDataRendered)="reconcileTable.headerHeightSetter()"
      (columnResized)="reconcileTable.headerHeightSetter()" [pagination]="true"
      [paginationPageSize]="reconcileTable.paginationPageSize"
      [paginationNumberFormatter]="reconcileTable.paginationNumberFormatter" [domLayout]="reconcileTable.domLayout">
    </ag-grid-angular>
  </nb-card-body>
  <nb-card-footer class="clear-fix">
    <button *ngIf="closeKit" type="button" nbButton outline status="danger" class="float-left"
      (click)="overrideConfirm()" nbTooltip="accept the errors and continue to close job"
      nbTooltipStatus="danger">Override</button>
    <button type="button" nbButton outline status="success" class="float-right" (click)="confirm()">Continue
      Processing</button>
  </nb-card-footer>
</nb-card>