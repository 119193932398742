import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
 name: 'httpify'
})

export class HttpifyPipe implements PipeTransform {

transform(value: string): string {
    if (value) {
        var result
        var startingUrl = 'http://'
        var httpsStartingUrl = 'https://'
        if (value.indexOf(startingUrl) == 0 || value.indexOf(httpsStartingUrl) == 0) {
          result = value
        } else {
          result = startingUrl + value
        }
        return result
      }
   }
}
