export interface manifestReconcileResult {
  fullMatch: manifestReconcileMatch,
  sentTape: {
    barcode: string | null,
    serial: string | null,
    cm: string | null
  }
  matchedTape: {
    barcode: string | null,
    serial: string | null,
    cm: string | null,
    goal: manifestGoal | null
  }
}
export enum manifestReconcileMatch {
  NO_MATCH = 0,
  FULL_MATCH = 1,
  PARTIAL_MATCH = 2,
  MISSING_TAPE = 3
}

export enum atMessage {
  AT_PLAIN_MESSAGE = 'A',
  AT_KIT_MESSAGE = 'E',
  AT_SWAT_MESSAGE = 'D',
  DRIVE_SWAT_FAIL = 'T',
  DRIVE_SWAT_PASS = 'S',
  DRIVE_KIT_FAIL = 'L',
  DRIVE_KIT_PASS = 'K'
}

export enum manifestGoal {
  ERASE = 'erase',
  DESTROY = 'destroy'
}