import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoggerService } from './logging/logger.service';
import { BodyDropPivotTarget } from 'ag-grid-community';
import { TapeSearch } from '../insurgo-interfaces/tape';
import { TapeValuation } from '../insurgo-interfaces/tape-value';

@Injectable({
  providedIn: 'root'
})
export class TapeHelpService {

  apiUrl = environment.apiUrl;

  constructor(
    public authHttp: HttpClient,
    private readonly logger: LoggerService
  ) { }

  isNewTape(modelType: string, mountCount: number): number {
    if (mountCount === 0) {
      return 1;
    } else if (modelType === "3592" && mountCount <= 2) {
      return 1;
    } else {
      return 0;
    }
  }

  getModelByID(typeID: number): Observable<any> {
    const URL = `${this.apiUrl}/tape/model/${typeID}`;

    return this.authHttp.get(URL)
      .pipe(
        catchError(this.handleError('getModelByID', URL))
      );
  }

  getAllModels(): Observable<any[]> {
    const URL = `${this.apiUrl}/tape/model/`;

    return this.authHttp.get(URL)
      .pipe(
        catchError(this.handleError('getAllModels', URL))
      );
  }

  saveNewModel(typeID: string, modelName: string): Observable<number> {
    const URL = `${this.apiUrl}/tape/model`;
    const postData = {
      model: modelName,
      modelType: typeID
    }
    return this.authHttp.post(URL, postData)
      .pipe(
        catchError(this.handleError('saveNewModel', URL))
      );
  }

  saveManuf(manufacturerName: string): Observable<number> {
    const URL = `${this.apiUrl}/tape/manufacturer`;
    const postData = {
      manufacturer: manufacturerName
    }
    return this.authHttp.post(URL, postData)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError('saveManuf', URL))
      );
  }
  getAllManufacturers(): Observable<any> {
    const URL = `${this.apiUrl}/tape/manufacturer/`;

    return this.authHttp.get(URL)
      .pipe(
        catchError(this.handleError('getAllManufacturers', URL))
      );
  }


  getKitsCreatedBetweenDates(startSearchMS: number, endSearchMS: number): Observable<TapeSearch[]> {
    const URL = `${this.apiUrl}/reports/summary/tapeByMonth/${startSearchMS}/${endSearchMS}`;

    return this.authHttp.get<TapeSearch[]>(URL)
      .pipe(
        catchError(this.handleError('getKitsCreatedBetweenDates', URL))
      );
  }

  /**
   * 
   * @param startSearchMS 
   * @param endSearchMS 
   * @param groupBy 
   * @param completed if true only searching for complete tapes (destroyed or scanned out) if false search by asset tracked
   * @returns 
   */
  getKitsCreatedBetweenDatesGroupBy(startSearchMS: number, endSearchMS: number, groupBy: string, completed: boolean): Observable<TapeSearch[]> {
    const URL = `${this.apiUrl}/reports/summary/tapeGroup/${startSearchMS}/${endSearchMS}/${groupBy}`;

    let params = new HttpParams();
    params = params.append('completedAssets', completed.toString());

    return this.authHttp.get<TapeSearch[]>(URL, { params: params })
      .pipe(
        catchError(this.handleError('getKitsCreatedBetweenDatesGroupBy', URL))
      );
  }


  async getAllTapeValuations(): Promise<TapeValuation[]> {
    let activeValuations = await this.allTapeValuations().toPromise()
    activeValuations.forEach(valuation => {
      if (!valuation.tapeManufacturerID) {
        valuation.tapeManufacturer = 'Any'
      }
    });

    return activeValuations
  }

  private allTapeValuations(): Observable<TapeValuation[]> {
    const URL = `${this.apiUrl}/tape/value/active`;

    return this.authHttp.get(URL)
      .pipe(
        catchError(this.handleError('getAllTapeValuations', URL))
      );
  }

  getModelTapeValuations(modelID): Observable<TapeValuation[]> {
    const URL = `${this.apiUrl}/tape/value/search/${modelID}`;

    return this.authHttp.get(URL)
      .pipe(
        catchError(this.handleError('getModelTapeValuations', URL))
      );
  }

  createTapeValuation(_tapeValue: string, _tapeModelID: number, _tapeManufacturerID?: number) {
    const URL = `${this.apiUrl}/tape/value/`;
    const data = {
      tapeValue: _tapeValue,
      tapeModelID: _tapeModelID,
      tapeManufacturerID: _tapeManufacturerID
    }
    return this.authHttp.post(URL, data)
      .pipe(
        catchError(this.handleError('createTapeValuation', URL))
      );
  }

  editTapeValuation(_tapeValueID: number, _tapeValue: string) {
    const URL = `${this.apiUrl}/tape/value`;
    const data = {
      tapeValue: _tapeValue,
      tapeValueID: _tapeValueID
    }
    return this.authHttp.patch(URL, data)
      .pipe(
        catchError(this.handleError('editTapeValuation', URL))
      );
  }

  removeTapeValuation(_tapeValueID: number) {
    const URL = `${this.apiUrl}/tape/value/remove`;
    const data = {
      tapeValueID: _tapeValueID
    }
    return this.authHttp.patch(URL, data)
      .pipe(
        catchError(this.handleError('editTapeValuation', URL))
      );
  }

  private handleError(operation: String, url: String): any {
    return (err: any) => {
      const errMsg = `Error in ${operation}() retrieving ${url}`;
      this.logger.error(`${errMsg}`);
      if (err instanceof HttpErrorResponse) {
        // you could extract more info about the error if you want, e.g.:
        this.logger.error(`status: ${err.status}, ${err.statusText}`);
      }

      return ErrorObservable.create(errMsg);
    };
  }
}