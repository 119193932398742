<nb-card style="min-width: 80vw; max-height: 90vh; max-width: 90vw;">
  <nb-card-header>
    Analysis
  </nb-card-header>
  <nb-card-body style="overflow-y: auto;">
    <!-- 
    {{tapeAnalysis | json}} -->
    <ng-container *ngFor="let page of tapeAnalysis.analysis">
      <div class="row">
        <div class="col-12">
          <h6>{{page.pageName}}, v:{{page.pageVersion}} / d:{{page.pageDecodedVersion}}</h6>
        </div>
      </div>
      <div class="row" *ngFor="let field of page.pageData">
        <div class="col-3">
          {{field.description}}
        </div>
        <div class="col-9">
          {{field.rawData | json}}
        </div>
      </div>
      <hr>
    </ng-container>
  </nb-card-body>
</nb-card>