<nb-card>
  <nb-card-header>Create New Valuation</nb-card-header>
  <nb-card-body>
    <form [formGroup]="newValuationForm" (ngSubmit)="saveNewValuation()">
      <div class="row">
        <div class="col-12 col-md-3">
          <ng-select [items]="tapeTypeOptions" appendTo="body" placeholder="Select Type" formControlName="tapeType"
            bindLabel="tapeModel" bindValue="tapeModelID">
          </ng-select>
        </div>
        <div class="col-12 col-md-3">
          <ng-select [items]="tapeManufacturerOptions" appendTo="body" placeholder="Select Manufacturer"
            formControlName="tapeManuf" bindLabel="manufacturerName" bindValue="tapeManufacturerID">
          </ng-select>
        </div>
        <div class="col-12 col-md-3">
          <ng-select [items]="tapeValueOptions" appendTo="body" placeholder="Select Value"
            formControlName="initialValuation" bindLabel="value" bindValue="value">
          </ng-select>
        </div>
        <div class="col-12 col-md-3 text-center" >
          <button nbButton fullWidth type="submit" status="success" [disabled]="!newValuationForm.valid">Save</button>

        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
<nb-card>
  <nb-card-header>Existing Tape Valuations</nb-card-header>
  <nb-card-body>
    <p>Double click a row to edit a tape's value</p>
    <ng-container *ngIf="activeValuations.length>0">

      <ag-grid-angular #agGrid style="width: 100%; height: 500px;" class="ag-theme-alpine" [rowData]="activeValuations"
        [columnDefs]="columnDefs" [context]="context" [frameworkComponents]="frameworkComponents"
        [defaultColDef]="defaultColDef" [gridOptions]="gridOptions" [enableCellTextSelection]="true">
      </ag-grid-angular>
    </ng-container>
  </nb-card-body>
</nb-card>