import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule, NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbUserModule,
  NbTabsetModule,
  NbTooltipModule,
  NbToggleModule,
  NbAccordionModule,
  NbProgressBarModule,
  NbSpinnerModule
} from '@nebular/theme';
import { CountrySelectorComponent } from './country-selector/country-selector.component';
import { CountySelectorComponent } from './county-selector/county-selector.component';
import { AddressSelectorComponent } from './address-selector/address-selector.component';
import { CompanySelectorComponent } from './company-selector/company-selector.component';
import { WipKitCardComponent } from './wip-kit-card/wip-kit-card.component';
import { TapeInformationComponent } from './tape-information/tape-information.component';
import { KitSelectorComponent } from './kit-selector/kit-selector.component';
import { FormFieldErrorComponent } from './form-field-error/form-field-error.component';
import { TapeScanDetailsComponent } from './tape-scan-details/tape-scan-details.component';
import { ScannerSelectorComponent } from './scanner-selector/scanner-selector.component';
import { ProcessSelectorComponent } from './process-selector/process-selector.component';
import { KitDetailsComponent } from './kit-details/kit-details.component';
import { ModelSelectorComponent } from './model-selector/model-selector.component';
import { ManufacturerSelectorComponent } from './manufacturer-selector/manufacturer-selector.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { NumbersOverviewComponent } from './numbers-overview/numbers-overview.component';
import { TapeChartComponent } from './tape-chart/tape-chart.component';
import { JobWipComponent } from './job-wip/job-wip.component';
import { JobCreatedChartComponent } from './job-created-chart/job-created-chart.component'
import { TmNgOdometerModule } from 'tm-ng-odometer';
import { NgxEchartsModule } from 'ngx-echarts';
import { AgGridModule } from 'ag-grid-angular';

import { ProgressBarComponent } from './job-wip/progress-bar.component';
import { TapeRaceComponent } from './tape-race/tape-race.component'
import { JobTypeSelectorComponent } from './job-type-selector/job-type-selector.component';
import { TapeAnalysisViewerComponent } from './tape-analysis-viewer/tape-analysis-viewer.component';
import { ManifestItemsDisplayComponent } from './manifest-items-display/manifest-items-display.component';
import { ManifestSummaryComponent } from './manifest-summary/manifest-summary.component';

@NgModule({
  imports: [
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    NbProgressBarModule,
    CommonModule,
    NgSelectModule,
    FormsModule,
    NbTabsetModule,
    NbTooltipModule,
    NbToggleModule,
    NbAccordionModule,
    NbSpinnerModule,
    TmNgOdometerModule,
    NgxEchartsModule,
    AgGridModule.withComponents([ProgressBarComponent])

  ],
  declarations: [
    AddressSelectorComponent,
    CompanySelectorComponent,
    WipKitCardComponent,
    TapeInformationComponent,
    CountySelectorComponent,
    CountrySelectorComponent,
    KitSelectorComponent,
    FormFieldErrorComponent,
    TapeScanDetailsComponent,
    ScannerSelectorComponent,
    ProcessSelectorComponent,
    KitDetailsComponent,
    ModelSelectorComponent,
    AutoFocusDirective,
    ManufacturerSelectorComponent,
    NumbersOverviewComponent,
    TapeChartComponent,
    JobWipComponent,
    JobCreatedChartComponent,
    ProgressBarComponent,
    TapeRaceComponent,
    JobTypeSelectorComponent,
    TapeAnalysisViewerComponent,
    ManifestItemsDisplayComponent,
    ManifestSummaryComponent
  ],
  exports: [
    NgSelectModule,
    AddressSelectorComponent,
    CompanySelectorComponent,
    WipKitCardComponent,
    TapeInformationComponent,
    CountySelectorComponent,
    CountrySelectorComponent,
    KitSelectorComponent,
    FormFieldErrorComponent,
    TapeScanDetailsComponent,
    ScannerSelectorComponent,
    ProcessSelectorComponent,
    KitDetailsComponent,
    ModelSelectorComponent,
    AutoFocusDirective,
    ManufacturerSelectorComponent,
    NumbersOverviewComponent,
    TapeChartComponent,
    JobWipComponent,
    JobCreatedChartComponent,
    NgxEchartsModule,
    TapeRaceComponent,
    JobTypeSelectorComponent,
    TapeAnalysisViewerComponent,
    ManifestSummaryComponent
  ]
})

export class InsurgoUIComponents { }
