import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { ModalConfirmationService } from '../confirmation-modal/confirmation-modal.service';
import { AddressService } from '../../address.service'
import { NgForm } from '@angular/forms';
import { PostalAddress } from '../../../insurgo-interfaces/address';

import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from '@nebular/theme';


@Component({
  templateUrl: './viewAddress.html',
})

export class ViewAddressModalComponent implements OnInit {
  @Input() addressID: number;
  @Input() companyName: string;
  @Input() companyID: number;
  loaded = false;

  address: PostalAddress;
  originalData: Object;

  formError: any = {
    submitting: false,
    error: false,
    success: false
  };

  @ViewChild('editAddressForm') editAddressForm: NgForm;

  constructor(
    protected ref: NbDialogRef<ViewAddressModalComponent>,
    private _modalConfirmationService: ModalConfirmationService,
    private _addressService: AddressService,
    private toastrService: NbToastrService
  ) { }

  async ngOnInit() {
    if (this.addressID) {
      await this.getData();
    } else {
      this.address = <PostalAddress>{ country:"United Kingdom" };
      this.loaded = true;
    }
  }

  async getData(){
    try {
      this.loaded = false;
      let data = <any> await this._addressService.getAddressByID(this.addressID).toPromise();
      this.address = <PostalAddress>this.deepCopy(<Object>data);
      this.originalData =  <PostalAddress>this.deepCopy(<Object>data);
      this.loaded = true;
    } catch (error) {
      this.showToast("danger","Fetch Error", `Error occurred getting the address data!`, 'activity');
      this.ref.close(false);
    }
  }

  async resetForm(){
    await this.getData();
    setTimeout(function() {
      this._markFormPristine();
    }.bind(this), 500);
  }

  private _markFormPristine(): void {
    const { editAddressForm: { value: formValueSnap } } = this; // const formValueSnap = this.myForm.value;
    this.editAddressForm.reset(formValueSnap);
    Object.keys(this.editAddressForm.controls).forEach(control => {
      this.editAddressForm.controls[control].markAsPristine();
      this.editAddressForm.controls[control].markAsUntouched();
    });
  }

  submit(): void {
    // set some realistic defaults
    this.formError.submitting = true;
    this.formError.success = false;
    this.formError.error = false;
    if(this.addressID){
      this._addressService.updateAddress(this.address).subscribe(
        (response) => {
          this.formError.success = true;
          setTimeout(function() {
            this.formError.submitting = false;
            this.formError.success = false;
            this.resetForm();
          }.bind(this), 3000);
        }, 
        (err) => {
          console.log(err);
          this.formError.error = true;
          setTimeout(function() {
            this.formError.submitting = false;
          }.bind(this), 3000);
        }
      )
    } else {
      //new company
      this.address.companyID = this.companyID
      this._addressService.insertAddress(this.address).subscribe(
        (response) => {
          this.formError.success = true;
          this.addressID = response.addressID;
          setTimeout(function() {
            this.formError.submitting = false;
            this.formError.success = false;
            this.resetForm();
          }.bind(this), 3000);
        }, 
        (err) => {
          console.log(err);
          this.formError.error = true;
          setTimeout(function() {
            this.formError.submitting = false;
          }.bind(this), 3000);
        }
      )
    }
    
  }

  reset(form:NgForm){
    this.address = <PostalAddress> this.deepCopy(<Object>this.originalData);
    this.formError.submitting = false;
    this.formError.success = false;
    setTimeout(function() {
            this._markFormPristine();
    }.bind(this), 500);
  }



  async cancel(dirty) {
    if (dirty) {
      const result =  await this._modalConfirmationService.confirm('Still Editing the form?', 'You have not finished editing, are you sure you want to cancel?');
      if (result === 'confirm') {
        this.ref.close(false);
      } else if (result === 'cancel') {

      } else if (result === 'decline') {

      }
    } else {
      this.ref.close(false);
    }
  }

  private showToast(type: NbComponentStatus, title: string, body: string, iconName: string = 'alert-triangle-outline') {
    const config = {
      status: type,
      destroyByClick: false,
      duration: 4000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: true,
      icon: iconName,
      pack: 'eva' 
    };

    this.toastrService.show(
      body,
      title,
      config
    );
  }

  displayFieldCss(form: NgForm, field: any) {
    if (form.dirty) {
      if (field.touched && field.invalid) {
        //invalid
        return 'danger';
      } else if (field.valid) {
        //valid
        return 'success';
      } else {
        //needs to be filled in
        return 'warning';
      }
    } else {
      return 'basic';
    }
  }

  deepCopy(obj): any {
    let copy;
    // Handle the 3 simple types, and null or undefined
    if (obj == undefined || typeof obj !== 'object') { return obj; }
    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());

        return copy;
    }
    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.deepCopy(obj[i]);
        }

        return copy;
    }
    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) { copy[attr] = this.deepCopy(<Object>obj[attr]); }
        }

        return copy;
    }
    throw new Error(`Unable to copy obj! Its type isn't supported.`);
  }
}

