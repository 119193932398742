<div class="row">
  <form #addUserForm="ngForm">
    <nb-card accent="info" >
      <nb-card-header class="text-center clear-fix">
        <button nbButton outline size="tiny" status="info" class="float-right" (click)=cancel(addUserForm.form.dirty)>x</button>
        <br>
        <div *ngIf="userID"> Edit {{user.name}} </div>
        <div *ngIf="!userID">New User</div>
      </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group mb-0">                      
              <label for="firstNameInput" class="label" required>First Name</label>
              <input type="text" nbInput fullWidth required [status]="displayFieldCss(addUserForm, firstNameInput)" maxlength="50" id="firstNameInput" name="firstNameInput" [(ngModel)]="user.firstName" #firstNameInput="ngModel" fieldSize="small">   
              <app-form-field-error [displayError]="firstNameInput.touched && firstNameInput.invalid" [displaySuccess]="firstNameInput.valid" errorMsg="First name is required"></app-form-field-error>
            </div>
            <div class="form-group mb-0">                      
              <label for="lastNameInput" class="label">Last Name</label>
              <input type="text" nbInput fullWidth required [status]="displayFieldCss(addUserForm, lastNameInput)" maxlength="50" id="lastNameInput" name="lastNameInput" [(ngModel)]="user.lastName" #lastNameInput="ngModel" fieldSize="small">   
              <app-form-field-error [displayError]="lastNameInput.touched && lastNameInput.invalid" [displaySuccess]="lastNameInput.valid" errorMsg="Last name is required"></app-form-field-error>
            </div>
            <div class="form-group mb-0">                      
              <label for="emailInput" class="label">Email Address</label>
              <input type="email" nbInput fullWidth required [status]="displayFieldCss(addUserForm, emailInput)" maxlength="50" id="emailInput" name="emailInput" [(ngModel)]="user.email" #emailInput="ngModel" fieldSize="small">      
              <app-form-field-error [displayError]="emailInput.touched && emailInput.invalid" [displaySuccess]="emailInput.valid" errorMsg="Email is required or Invalid"></app-form-field-error>
            </div>
            <div class="form-group mb-0">
              <label for="typeInput" class="label">User Type</label>
              <ng-select
                  [(ngModel)]='user.userRole'
                  [items] = "userRoles"
                  appendTo="body"
                  placeholder="Select user type..."
                  bindLabel = "description"
                  bindValue = "id"
                  required
                  id="typeInput" 
                  name="typeInput"
                  #typeInput="ngModel"
                  class="inModal"
              >
              </ng-select>
              <!-- <nb-select fullWidth placeholder="Select user type..." [(selected)]="user.userRole">
                <nb-option value="1">Standard User</nb-option>
                <nb-option value="9">Admin</nb-option>
              </nb-select> -->

              <app-form-field-error [displayError]="typeInput.touched && typeInput.invalid" [displaySuccess]="typeInput.valid" errorMsg="Type is required"></app-form-field-error>
            </div>
          </div>
        </div>
      </nb-card-body>
      <nb-card-footer class="clear-fix">
        <div class="alert alert-danger" *ngIf='formError.error'>Saving user failed...</div>              
        <div class="alert alert-success" *ngIf='formError.success'>Saving user was successful!</div>
        <button *ngIf="userID" nbButton outline status="success" [disabled]="addUserForm.invalid || formError.submitting || !addUserForm.dirty" type="submit" value="submit" (click)="submit()" class="float-right">Save</button>
        <button *ngIf="!userID" nbButton outline status="success" [disabled]="addUserForm.invalid || formError.submitting || !addUserForm.dirty" type="submit" value="submit" (click)="submit()" class="float-right">Add</button>
        <button nbButton outline status="danger" *ngIf="addUserForm.dirty || addUserForm.dirty && !formError.submitting" class="float-left" (click)=reset(addUserForm)>Cancel</button>
      </nb-card-footer>
    </nb-card>
  </form>
</div>
