import { Component, Input, OnInit, Output, EventEmitter, forwardRef} from '@angular/core';
import { AddressService } from '../../insurgo-services/address.service';
import { PostalAddress } from '../../insurgo-interfaces/address';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, FormControl, NumberValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-address-selector',
  template: `<ng-select
                  [(ngModel)]  ='_selectedData'
                  [items] = "loadedData"
                  (change) = "change($event)"
                  (open) = "touch()"
                  placeholder="Select address..."
                  bindLabel = "addressString"
                  bindValue = "addressID"
                  notFoundText = "No addresses found..."
                  [disabled] = "_disabled"
                  labelForId= "addressSelector"
                  appendTo="body"
              >
              </ng-select>
        `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressSelectorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressSelectorComponent),
      multi: true
    }
  ],
})

export class AddressSelectorComponent implements OnInit, ControlValueAccessor, Validator {
  constructor(
    private readonly _addressService: AddressService
  ) { }

  @Input() required = false; // default required as false
  @Input() name = '';
  @Input() set companyID(id:number){
    this._companyID = id;
    this.getAllData();
  };
  @Input() showHidden = false;
  @Output() private onChange = new EventEmitter();
  _companyID:number;
  _selectedData: number;
  _invalid = true; // always invalid at the start
  _disabled = false;
  loadedData: any = [];
  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  touch() {
    this.propagateTouch(null);
  }

  get runChangeDetection() {
    console.log('Checking the view');
    return true;
  }

  validate(c: FormControl) {
    if (this.required) {
      return (!this._invalid) ? null : {required: {valid: false}};
    } else {
      return null;
    }
  }

  writeValue(value: any) {
    this._selectedData = value;
    if (!value) {
      this._invalid = true;
    } else {
      this._invalid = false;
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  ngOnInit(): any {
    this.getAllData();
  }

  change(_newData): void {
    if (!_newData) {
      // this._selectedData = null;
      this._invalid = true;
      this.propagateChange(null);
      this.onChange.emit(null);
    } else {
      // this._selectedData = _newData;
      this._invalid = false;
      this.propagateChange(this._selectedData);
      this.onChange.emit(this._selectedData);
    }
  }

  private getAllData(): void {
    // reset view for component refresh
    if(this._companyID == null || this._companyID < 0){
      this._addressService.getAllAddresses(this.showHidden).subscribe( (data: PostalAddress[]) => {
        this.loadedData = data.map(i => {
          i.addressString = `${i.inUse ? '' : '(Inactive)'} ${i.line1 + ','} ${(i.line2 || '') === '' ? '': i.line2 + ','} ${i.city  + ','} ${i.postalCode}`;
          return i;
        });
      },
      (err) => {
        console.log('Error loading addresses...')
      });
    } else {
      this._addressService.getAddressesByCompanyID(this._companyID, this.showHidden).subscribe( (data: PostalAddress[]) => {
        this.loadedData = data.map(i => {
          i.addressString = `${i.inUse ? '' : '(Inactive)'} ${i.line1 + ','} ${(i.line2 || '') === '' ? '': i.line2 + ','} ${i.city  + ','} ${i.postalCode}`;

          return i;
        });
      },
      (err) => {
        console.log('Error loading addresses...')
      });
    }
   
  }
}
