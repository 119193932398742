import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LoggerService } from './logging/logger.service';
import { catchError, mergeMap, map } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { Drive } from '../insurgo-interfaces/drive';
import { Observable, of } from 'rxjs';

@Injectable()
export class DriveHelperService {

  apiUrl = environment.apiUrl;

  constructor(
    public authHttp: HttpClient,
    private readonly logger: LoggerService
  ) { }


  getDriveFromApData(apData: string): Observable<Drive> {
    let driveSerial = '';

    apData = apData.replace(/\s+/g, ''); // remove any spaces
    // does it contain "KIT:ERASED"
    if (apData.includes('KIT:ERASED')) {
      // get the drive serial
      driveSerial = apData.substring(13, 25);
      // get drive info  
    } else if (apData.includes('SWAT')) {
      driveSerial = apData.substring(14, 26);
    } else if (apData.includes('K2(E):') || apData.includes('K2(S):')) {
      driveSerial = apData.substring(apData.indexOf(':') + 1, apData.indexOf(';'));
    } else {
      driveSerial = 'invalid';
      return of({
        driveSerial: driveSerial,
        valid: false
      })
    }


    return this.getDrive(driveSerial);
  }

  getDrive(driveSerial: string | undefined): Observable<Drive> {

    if (!driveSerial) {
      let _fakeDrive: Drive = {
        driveSerial: 'invalid',
        valid: false
      }
      return of(_fakeDrive);
    }

    let URL = `${this.apiUrl}/facility/drive/report/${driveSerial}`;
    return this.authHttp.get<Drive[]>(URL)
      .pipe(
        map((drives) => {
          if (drives.length > 0) {
            return drives[0];
          } else {
            // drive doesn't exists return empty invalid drive
            let _fakeDrive: Drive = {
              driveSerial: driveSerial,
              valid: false
            }
            return _fakeDrive;
          }
        }),
        catchError(this.handleError('getDrives', URL))
      );
  }

  getAllDrives(): Observable<Drive[]> {
    let URL = `${this.apiUrl}/facility/drive/report`;

    return this.authHttp.get<Drive[]>(URL)
      .pipe(
        catchError(this.handleError('getAllDrives', URL))
      );
  }

  saveNewKitDrive(driveSerial, driveLocationID): Observable<number> {
    const URL = `${this.apiUrl}/facility/drive/`;
    var _data = {
      driveSerial: driveSerial,
      driveName: 'unknown',
      driveLocationID: driveLocationID
    }
    return this.authHttp.post<number>(URL, _data)
      .pipe(
        catchError(this.handleError('getKitDriveDetails', URL))
      );
  }

  activateDrive(_driveJWT): Observable<boolean> {
    const URL = `${this.apiUrl}/facility/drive/activate`;
    let _data = {
      driveJWT: _driveJWT
    }
    return this.authHttp.post<number>(URL, _data)
      .pipe(
        catchError(this.handleError('activateDrive', URL))
      );
  }


  private handleError(operation: String, url: String): any {
    return (err: any) => {
      const errMsg = `Error in ${operation}() retrieving ${url}`;
      this.logger.error(`${errMsg}`);
      if (err instanceof HttpErrorResponse) {
        // you could extract more info about the error if you want, e.g.:
        this.logger.error(`status: ${err.status}, ${err.statusText}`);
      }

      return ErrorObservable.create(errMsg);
    };
  }





}
