import { InvestigoState } from "./tape";

export interface Process {
    processID: number;
    scannerID: number;
    scannerUID: number;
    tapeID: number;
    processTypeID: number;
    processType: string;
    timeOfScan: any;
    scannerLocationID: number;
    facilityID: number;
    userID: number;
    processNotes: any;
}

export interface ProcessHistory {
    processes: Process[]
}

export enum ProcessTypes {
    AssetTrack = 1,
    Destruction = 2,
    Erasure = 3,
    Initialised = 4,
    Sale = 5,
    SiteScan = 6,
    StockScan = 7,
    ScanOut = 8,
    TapeAudit = 9,
    TapeAnalysis = 10,
    HKD_Degaussed = 21,
    HKD_SWAT = 22
}

export enum ProcessTypesString {
    HKD_Degaussed = "Degauss",
    HKD_SWAT = "SWAT"
}


export interface ProcessNotes_Degauss {
    mountCount: number;
    isCMKilled: boolean;
    method: 'Degaussed';
    // appData: string;
    driveNotes: {
        driveSerial: string,
        driveType: 1 | 2,
        dateSec: number,
        driveName: string
    } | {}
    investigoState: InvestigoState;
}

export interface ProcessNotes_SWAT {
    mountCount: number;
    isCMKilled: boolean;
    method: 'SWAT';
    // appData: string;
    driveNotes: {
        driveSerial: string,
        driveType: 1 | 2,
        dateSec: number,
        driveName: string
    }
    investigoState: InvestigoState;
}