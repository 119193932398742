<nb-card accent="info">
    <nb-card-header class ="clear-fix">
        Client Management  
        <button nbButton size="tiny" status="info" (click)=reloadCompanies() class="float-right"><nb-icon icon="refresh-outline"></nb-icon></button>
    </nb-card-header>
    <nb-card-body class="text-center">
        <button nbButton size="small" class="mb-2" status="info" (click)=addCompany()><nb-icon icon="plus-circle-outline"></nb-icon> Add New Client</button>
        <p class="d-block d-lg-none">This table is not optimised for your device, try horizontal scrolling</p>
        <ag-grid-angular
            #agGrid
            style="width: 100%; height: 500px;"
            class="ag-theme-alpine"
            [rowData]="clients"
            [columnDefs]="columnDefs"
            [context]="context"
            [frameworkComponents]="frameworkComponents"
            [defaultColDef]="defaultColDef"
            [gridOptions]="gridOptions"
            [enableCellTextSelection]="true"
            >
        </ag-grid-angular>
    </nb-card-body>
    <!-- <nb-card-footer class="clear-fix">
    </nb-card-footer> -->
</nb-card>