import { Component, Input, OnInit, Output, EventEmitter, forwardRef } from '@angular/core';
import { CompanyService } from '../../insurgo-services/company.service';
import { Company } from '../../insurgo-interfaces/company';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Component({
  selector: 'app-company-selector',
  template: `<ng-select
                  [(ngModel)]  ='_selectedData'
                  [items] = "loadedData"
                  (change) = "change($event)"
                  (open) = "touch()"
                  placeholder="Select company..."
                  bindLabel = "companyName"
                  bindValue = "companyID"
                  notFoundText = "No companies found..."
                  [disabled] = "_disabled"
                  labelForId= "companySelector"
                  appendTo="body"
              >
              </ng-select>
        `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanySelectorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CompanySelectorComponent),
      multi: true
    }
  ]
})

export class CompanySelectorComponent implements OnInit, ControlValueAccessor, Validator {
  constructor(
    private readonly _companyService: CompanyService
  ) { }

  @Input() required = false; // default required as false
  @Input() name = '';
  @Output() private onChange = new EventEmitter();

  _selectedData: number;
  _invalid = true; // always invalid at the start
  _disabled = false;
  loadedData: any = [];
  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  touch() {
    this.propagateTouch(null);
  }

  validate(c: FormControl) {
    if (this.required) {
      return (!this._invalid) ? null : {required: {valid: false}};
    } else {
      return null;
    }
  }

  writeValue(value: any) {
    this._selectedData = value;
    if (!value) {
      this._invalid = true;
    } else {
      this._invalid = false;
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  ngOnInit(): any {
    this.getAllData();
  }

  change(_newData): void {
    if (!_newData) {
      // this._selectedData = null;
      this._invalid = true;
      this.propagateChange(null);
      this.onChange.emit(null);

    } else {
      // this._selectedData = _newData;
      this._invalid = false;
      this.propagateChange(this._selectedData);
      this.onChange.emit(this._selectedData);
    }
  }

  private getAllData(): void {
    // reset view for component refresh
    this._companyService.getCompanies().subscribe( (data: Company[]) => {
          this.loadedData = data;
    },
    (err) => {
      console.log('Error loading companies...')
    });
  }
}
