import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ViewAddressModalComponent } from './viewAddress.component';

@Injectable()
export class ViewAddressService {

    constructor(
      private dialogService: NbDialogService,
    ) {}

    viewAddressModal(addressID?:number, companyName?:string, companyID?:number) {

        const modal = this.dialogService.open(ViewAddressModalComponent, {
          context: {
            addressID: addressID,
            companyName: companyName,
            companyID: companyID
          },
        });

        return new Promise((resolve, reject) => {
          modal.onClose.subscribe((result) => {
              resolve(result);
          });
        });
      }
}