import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-field-error',
  template: `
            <span
              [class.glyphicon]="icon"
              [class.glyphicon-remove]="icon && displayError"
              [class.glyphicon-ok]="icon && displaySuccess"
              class="glyphicon form-control-feedback" aria-hidden="true">
            </span>
            <div *ngIf="displayError" >
                <span class="sr-only">(error)</span>
                <div class="error-msg"><small>{{errorMsg}}</small></div>
            </div>`,
  styles: [`
        .error-msg {
            color: #a94442;
        }
        .fix-error-icon {
            top: 27px;
        }`
]
})
export class FormFieldErrorComponent {
  @Input() errorMsg: string;
  @Input() displayError: boolean;
  @Input() displaySuccess: boolean;
  @Input() icon = true;
}
