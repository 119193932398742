import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError, finalize, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoggerService } from './logging/logger.service';
import { Company } from '../insurgo-interfaces/company';

@Injectable()
export class CompanyService {

  apiUrl = environment.apiUrl;

  constructor(
    public authHttp: HttpClient,
    private readonly logger: LoggerService
  ) { }

  getCompanyByID(companyID:number): Observable<Company> {
    const URL = `${this.apiUrl}/company/${companyID}`;
    return this.authHttp.get<Company>(URL)
      .pipe(
        catchError(this.handleError('getCompanyByID', URL))
      );
  }

  getCompanies(): Observable<Company[]> {
    const URL = `${this.apiUrl}/company/`;
    return this.authHttp.get<Company[]>(URL)
      .pipe(
        catchError(this.handleError('getCompanies', URL))
      );
  }

  newCompany(companyData: Company): Observable<any>{
    const URL = `${this.apiUrl}/company/`;
    return this.authHttp.post(URL, companyData)
      .pipe(
        catchError(this.handleError('newCompany', URL))
      );
  }

  updateCompany(companyData: Company): Observable<any>{
    const URL = `${this.apiUrl}/company/${companyData.companyID}`;
    return this.authHttp.put(URL, companyData)
      .pipe(
        catchError(this.handleError('updateCompany', URL))
      );
  }

  deleteCompany(companyID: number): Observable<any>{
    const URL = `${this.apiUrl}/company/${companyID}`;
    return this.authHttp.delete(URL)
      .pipe(
        catchError(this.handleError('deleteCompany', URL))
      );
  }

    //   _viewEditModal = function (idcompany) {
    //     return $uibModal.open({
    //       templateUrl: '/views/modals/viewCompanyModal.html',
    //       controller: 'viewCompanyModalCtrl',
    //       windowClass: 'animated bounceInUp',
    //       size: 'lg',
    //       backdrop: true,
    //       resolve: {
    //         idcompany: function () {
    //           return idcompany
    //         }
    //       }
    //     })
    //   }

    //   return {
    //     getCompanies: _getCompanies,
    //     getCompanyByID: _getCompanyByID,
    //     deleteCompany: _deleteCompany,
    //     viewEditModal: _viewEditModal,
    //     updateCompany: _updateCompany,
    //     newCompany: _newCompany
    //   }
    // })



  private handleError(operation: String, url: String): any {
    return (err: any) => {
        const errMsg = `error in ${operation}() retrieving ${url}`;
        this.logger.error(`${errMsg}:`, err);
        if (err instanceof HttpErrorResponse) {
            // you could extract more info about the error if you want, e.g.:
            // console.log(`status: ${err.status}, ${err.statusText}`);
        }
        return ErrorObservable.create(errMsg);
    };
  }
}
