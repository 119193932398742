import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule, NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbUserModule,
  NbTabsetModule,
  NbSpinnerModule
} from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';

import { ManagementRoutingModule } from './management-routing.module';
import { CreateKitComponent } from './create-kit/create-kit.component';
import { UsersComponent } from './users/users.component';
import { ClientsComponent } from './clients/clients.component';
import { InsurgoUIComponents } from '../../insurgo-ui-components/uiComponents.module'
import { KitService } from '../../insurgo-services/kit.service';
import { AgGridModule } from 'ag-grid-angular';
import { ModalServiceModule } from '../../insurgo-services/modalTemplates/modal-service.module';
import { MainPipeModule } from '../../insurgo-ui-components/pipes/pipes-module';
import { DrivesComponent } from './drives/drives.component';
import { TapeValueComponent } from './tape-value/tape-value.component'
import { SetTapeValueService } from '../../insurgo-services/modalTemplates/set-tape-value/set-tape-value.service';


@NgModule({
  declarations: [
    CreateKitComponent, 
    UsersComponent, 
    ClientsComponent, 
    DrivesComponent, 
    TapeValueComponent
  ],
  imports: [
    ThemeModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    NbTabsetModule,
    FormsModule,
    CommonModule,
    ManagementRoutingModule,
    InsurgoUIComponents,
    AgGridModule.withComponents([]),
    ModalServiceModule,
    MainPipeModule,
    ReactiveFormsModule,
    NbSpinnerModule,
  ],
  providers:[
    KitService,
    SetTapeValueService
  ]
})
export class ManagementModule { }
