import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import 'rxjs/add/operator/pairwise';
import { Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {

  constructor(
    private router: Router,
    angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private titleService: Title,
  ) {

    angulartics2GoogleTagManager.startTracking();

    this.router.events.pairwise().subscribe((e) => {
      if (!environment.production) {
        console.log(e);
      }
    });

    // set title name from environments
    this.titleService.setTitle(environment.siteName);

  }
}
