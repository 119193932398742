
<div class="col-8 offset-2 text-center">
  <img src="assets/images/logo.png" class="img-fluid pb-2" alt={{altText}}>
  <img *ngIf="showCustomerLogo.length > 0" [src]=showCustomerLogo class="img-fluid pb-2">
</div>

<h1 id="title" class="title">Login</h1>
<p class="sub-title">Hello! Log in with your email address.</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

  <div [formGroup]="loginForm" aria-labelledby="title" *ngIf="!messages.length || !submitted">
    <div class="form-control-group">
      <label for="input-email">Email address:</label>
      <input  nbInput
              fullWidth
              id="input-email"
              formControlName="email"
              [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
              [attr.aria-invalid]="email.invalid && email.touched ? true : null"
              placeholder="Email address"
              (keydown.enter)="login()">
      <ngx-validation-message label="Email address" [showPattern]="email?.hasError('pattern') && email.touched"
        [showRequired]="email?.errors?.required && email.touched"></ngx-validation-message>
    </div>

    <div class="form-control-group">
      <span class="label-with-link">
        <label class="label" for="input-password">Password:</label>
      </span>
      <input  nbInput
              fullWidth
              id="input-password"
              formControlName="password"
              type="password"
              [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
              placeholder="Password"
              (keydown.enter)="login()">
      <ngx-validation-message label="Password" [showMinLength]="password?.hasError('minlength') && password.touched"
        [showMaxLength]="password?.hasError('maxlength') && password.touched"
        [showRequired]="password?.errors?.required && password.touched"
        [minLength]="minLength"
        [maxLength]="maxLength"></ngx-validation-message>
      <span class="label-with-link">
        <a class="forgot-password caption-2" routerLink="../request-password">Forgot Password?</a>
      </span>
    </div>

    <div class="form-control-group accept-group">
      <nb-checkbox name="rememberMe" *ngIf="rememberMe">Remember me</nb-checkbox>
    </div>

    <button nbButton fullWidth status="primary" size="large"
    (click)="login()" type="submit" [disabled]="submitted || !loginForm.valid"
      [class.btn-pulse]="submitted">
      Log In
    </button>
  </div>

<section *ngIf="socialLinks && socialLinks.length > 0" class="links" aria-label="Social sign in">
  or enter with:
  <div class="socials">
    <ng-container *ngFor="let socialLink of socialLinks">
      <a *ngIf="socialLink.link"
         [routerLink]="socialLink.link"
         [attr.target]="socialLink.target"
         [attr.class]="socialLink.icon"
         [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
      <a *ngIf="socialLink.url"
         [attr.href]="socialLink.url"
         [attr.target]="socialLink.target"
         [attr.class]="socialLink.icon"
         [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
    </ng-container>
  </div>
</section>
<div class="row pt-5 text-center">
  <div class="col-6 offset-3">
    <h6>Powered by:</h6>
    <img src="assets/images/exineLogo.png" class="img-fluid pt-1" alt="Exine Technology Ltd">
  </div>
</div>

