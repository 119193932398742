<form [formGroup]="createJobForm" (ngSubmit)="submit()" enctype="multipart/form-data">
    <nb-card accent="info" [nbSpinner]="formError.submitting" nbSpinnerSize="giant" nbSpinnerStatus="primary">
        <nb-card-header>Create Job</nb-card-header>
        <nb-card-body class="text-center">
            <H1>New Job Number: <i>{{lastKitNumber}}</i></H1>
            <div class="row">
                <div class="form-group col-8 offset-2 pb-2">
                    <label>Select client(*):</label>
                    <app-company-selector required formControlName="companyID"></app-company-selector>
                    <div *ngIf="createJobForm.controls['companyID'].invalid && (createJobForm.controls['companyID'].dirty || createJobForm.controls['companyID'].touched)"
                        class="alert alert-danger">
                        <div *ngIf="createJobForm.controls['companyID'].errors.required">
                            Company is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="companyID">
                <div class="form-group col-8 offset-2 pb-2">
                    <label>Select address(*):</label>
                    <app-address-selector required formControlName="addressID"
                        [companyID]=companyID></app-address-selector>

                    <div *ngIf="createJobForm.controls['addressID'].invalid && (createJobForm.controls['addressID'].dirty || createJobForm.controls['addressID'].touched)"
                        class="alert alert-danger">
                        <div *ngIf="createJobForm.controls['addressID'].errors.required">
                            Address is required.
                        </div>
                    </div>
                </div>                
            </div>
            <div class="row" *ngIf="auditFunctionalityEnabled || sendAllFunctionalityEnabled">
                <div class="form-group col-8 offset-2 pb-2">
                    <label>Select job type(*):</label>
                    <app-job-type-selector required formControlName="jobTypeID"></app-job-type-selector>

                    <div *ngIf="createJobForm.controls['jobTypeID'].invalid && (createJobForm.controls['jobTypeID'].dirty || createJobForm.controls['jobTypeID'].touched)"
                        class="alert alert-danger">
                        <div *ngIf="createJobForm.controls['jobTypeID'].errors.required">
                            Job type is required.
                        </div>
                    </div>
                </div>              
            </div>
            <div class="row" *ngIf="companyID">

                <div class="form-group col-8 offset-2 pb-2">
                    <label>Input internal job number:</label>
                    <input type="text" class="form-control" formControlName="internalJobNumber"
                        name=internalJobNumberInput>
                </div>
            </div>
            <div class="row" *ngIf="companyID && !hideManifestInput">

                <div class="form-group col-8 offset-2 pb-2">
                    <label>Upload intended tape manifest:</label>
                    <div class="input-group">

                        <input type="text" id="file-input" formControlName="manifestFileName"
                            class="form-control align-quality" placeholder="Upload">
                        <div class="input-group-append">
                            <label for="file-upload-img" class="custom-file-2">
                                <button class="align-quality" style="pointer-events:fill" nbButton fullWidth
                                    status="info">
                                    Select
                                </button>
                            </label>
                            <input id="file-upload-img" type="file" (change)="onFileChange($event)"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                            <button class="align-quality" style="pointer-events:fill" nbButton fullWidth status="warning"
                                type="button" (click)="downloadTemplate()"  nbTooltip="download manifest template">
                                Template
                                <nb-icon icon="download-outline"></nb-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer class="clear-fix">
            <div class="alert alert-danger" *ngIf='formError.error'>Saving job failed...</div>
            <div class="alert alert-success" *ngIf='formError.success'>Job saved successfully!</div>
            <button nbButton outline status="danger" *ngIf="createJobForm.dirty && !formError.submitting"
                class="float-left" type="button" (click)=reset(createKitForm)>Cancel</button>
            <button nbButton outline status="success" [disabled]="createJobForm.invalid || formError.submitting"
                type="submit" value="submit" class="float-right">Create</button>
        </nb-card-footer>
    </nb-card>
    <!-- Debugging section -->
    <!-- <div class="debugging">
        <h3>Form Values:</h3>
        <pre>{{ createJobForm.value | json }}</pre>

        <pre>Touched {{ createJobForm.touched }}</pre>
        <pre>Valid {{ createJobForm.valid }}</pre>
        <pre>Valid {{ createJobForm.status }}</pre>
    </div> -->
</form>