import { Injectable } from '@angular/core';

export abstract class Logger {

  info: any;
  warn: any;
  error: any;
}

@Injectable()
export class LoggerService implements Logger {

  info: any;
  warn: any;
  error: any;
  // tslint:disable-next-line: no-empty
  invokeConsoleMethod(type: string, args?: any): void {}
}
