import { Component, OnInit } from '@angular/core';
import { DriveHelperService } from '../../../insurgo-services/drive-helper.service';
import { GenericInputService } from '../../../insurgo-services/modalTemplates/basic-input-modal/basic-input-modal.service';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import moment from 'moment';

@Component({
  selector: 'ngx-drives',
  templateUrl: './drives.component.html',
  styleUrls: ['./drives.component.scss']
})
export class DrivesComponent implements OnInit {

  public columnDefs;
  public context;
  public frameworkComponents;
  public defaultColDef;
  public gridOptions;

  constructor(    
    private _driveService: DriveHelperService,
    private _inputService: GenericInputService,
    private toastrService: NbToastrService,
    private _driveHelperService: DriveHelperService
  ) { 
    this.gridOptions = {

    }
    this.frameworkComponents = {

    };
    this.columnDefs = [
        {headerName: 'Name', field: 'driveName',minWidth:120, flex:1, sortable: true, sort: 'asc', tooltipField: "driveSerial"},
        {headerName: 'Serial ', field: 'driveSerial', minWidth:130, flex:1, sortable: true},
        {headerName: 'Type', field: 'driveType',minWidth:90, flex:1},
        {headerName: 'Activation Date', field: 'validFrom',minWidth:120, flex:1, valueFormatter: this.dateFormatter},
        {headerName: 'Validity', field: 'valid',minWidth:120, flex:1, tooltipField: "valid"},
        {headerName: 'Expiry Date', field: 'expiryDate',minWidth:120, flex:1, valueFormatter: this.dateFormatter },
        {headerName: 'Tapes Remaining', field: 'remaining',minWidth:120, flex:1, tooltipField: "percentRemaining" },
        {headerName: 'All Time Tapes', field: 'totalTapes',minWidth:120, flex:1}
    ];

    this.context = { componentParent: this };
    
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      filter: true,
      resizable: true,
    };
  }

  driveData;

  ngOnInit(): void {
    this.refreshDrives();

  }

  dateFormatter(params) {
    let formattedDate = moment(params.value, 'X').format('DD/MM/YYYY');
    if (formattedDate === 'Invalid date') {
      formattedDate = '';
    }
    return formattedDate;
  }

  async activateDrive() {
    try {
      let jwt = await this._inputService.show("Drive Activation Code","Please enter a drive activation code", "Enter", "Cancel", true);
      if(jwt !== "decline" && jwt !== "cancel" ){
        let result = await this._driveService.activateDrive(jwt).toPromise()
        if(result){
          this.showToast("success","Drive Activated", `A valid drive activation key was entered!`, 'checkmark-outline')
        } else {
          this.showToast("danger","Drive Activation Failed", `An invalid drive activation key was entered! (code: 1)`, 'trash-2-outline')
        }
        this.refreshDrives();
      }
    } catch (error) {
      this.showToast("danger","Drive Activation Failed", `An invalid drive activation key was entered! (code: 2)`, 'trash-2-outline')
    }
  }

  refreshDrives(){
    this._driveHelperService.getAllDrives().subscribe(
      (driveData: any) => {
        for (let i = 0; i < driveData.length; i++) {
          if(driveData[i].valid) {
            driveData[i].valid = `Valid (${driveData[i].maxTapes} Tapes)`
            driveData[i].remaining = driveData[i].maxTapes - driveData[i].tapesSinceActivation
            let _percent: number = 100 - ((driveData[i].tapesSinceActivation / driveData[i].maxTapes) * 100);
            driveData[i].percentRemaining = `${_percent.toFixed(0)}%`            
          } else {
            driveData[i].valid = `Invalid`
            driveData[i].remaining = 0
            driveData[i].percentRemaining = `0%`
          }

          if(driveData[i].driveType == 1) {
            driveData[i].driveType = "KIT"
          } else if(driveData[i].driveType === 2) {
            driveData[i].driveType = "SWAT"
          } else {
            driveData[i].driveType = "Unknown"
          }
          
        }
        this.driveData = driveData;
      }, 
      (err) => {
        this.showToast("danger","Drive Report Failed", `An error failed to get the available drives`, 'trash-2-outline')
      }
    )
  }

  private showToast(type: NbComponentStatus, title: string, body: string, iconName: string = 'alert-triangle-outline') {
    const config = {
      status: type,
      destroyByClick: false,
      duration: 4000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: true,
      icon: iconName,
      pack: 'eva' 
    };

    this.toastrService.show(
      body,
      title,
      config
    );
  }
}
