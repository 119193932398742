import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ModalConfirmationComponent } from './confirmation-modal.component';
import { Howl } from 'howler'

@Injectable()
export class ModalConfirmationService {

    constructor(
        private dialogService: NbDialogService
    ) {}

    confirm(titleMessage: string, bodyMessage: string, confirmButtonText?: string, cancelButtonText?: string, playWarning?: boolean): Promise<'confirm' | 'decline' | 'cancel'> {
        let _confirmButton = confirmButtonText != undefined ? confirmButtonText : 'Yes';
        let _cancelButton = cancelButtonText != undefined ? cancelButtonText : 'No';
       

        if(playWarning){
            let sound = new Howl({
                src:['../../../../assets/sound/warning.wav'],
                sprite: {
                    warningSnd: [0, 3500]
                }
            });
            sound.play('warningSnd')
        }
        const modal = this.dialogService.open(ModalConfirmationComponent, {
            closeOnBackdropClick: false,
            context: {
                title: titleMessage,
                bodyMessage: bodyMessage,  
                confirmButtonText: _confirmButton,
                cancelButtonText: _cancelButton,  
            },
          });

        return new Promise<'confirm' | 'decline' | 'cancel'>((resolve, reject) => {
            modal.onClose.subscribe((result) =>{
                resolve(result);
            });
          });
    }
}