import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {
  constructor() { }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else if (error.error instanceof HttpErrorResponse) {
      console.error('An error occurred:', error.error.message);
    } else if (error.error?.message) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError('Something bad happened; please try again later.');
  }

  handleErrorTrustedRoute(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
      return throwError(error.error.message);
    } else if (error.error instanceof HttpErrorResponse) {
      console.error('An error occurred:', error.error.message);
      return throwError(error.error.message);
    } else if (error.error?.message) {
      console.error('An error occurred:', error.error.message);
      return throwError(error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      return throwError('Something bad happened; please try again later.');
    }
   
  }
}
