import { Company } from './company';
import { PostalAddress } from './address';

export enum JobType {
    STANDARD = 1,
    SCAN_TO_STOCK = 2,
    DRIVE_TEST = 3,
    SWAT_TEST = 4,
    CLIENT_KIT = 5,
    TAPE_AUDIT = 6,
    SEND_ALL_TAPES = 7
}
export enum JobStatus {
    IN_PROGRESS = 2,
    COMPLETE = 3,
    COMPLETE_PENDING_APPROVAL = 4,
}

export interface Kit {
    kitID: number;
    addressID?: number;
    address?: PostalAddress;
    companyID?: number;
    company?: Company;
    kitStatusID: JobStatus;
    kitStatus?: string;
    collectionID?: string;
    dateCreated?: number;
    dateReceived?: number;
    dateCompleted?: number;
    dateCreatedHR?: string;
    dateReceivedHR?: string;
    dateCompletedHR?: string;
    kitNumber: string;
    orderTypeID: JobType;
    orderType?: string;
    daysInProgress?: number;
    wipInfo?: any;
    degaussingCost?: any;
    swatCost?: any;
    kitCost?: any;
    clientJobNumber?: string;
    selectLabel?: string;
    manifestID?: number;
    originalFileName?: string;


    totalUsedTapes?: number; // NOT INCLUDING NEW TAPES
    totalNewTapes?: number; // NOT INCLUDING NEW TAPES
    totalWIPTapes?: number
    totalATStatus?: number; // Asset Tracked
    totalERStatus?: number; // ERased
    totalDEStatus?: number; // DEstroyed
    totalISStatus?: number; // In Stock
    totalSDStatus?: number; // SolD
    totalSSStatus?: number; // Site Scanned
    totalSOStatus?: number; // Scanned Out
    tapeBreakdown?: any;

    totalAuditedTapes?: number;
    totalAnalysedTapes?: number;

}

export interface KitSearch {
    year: string,
    month: string,
    objects: Kit[]
}

