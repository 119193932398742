import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoggerService } from './logging/logger.service';
import { ErrorHandlingService } from './error-handling.service';
import * as FileSaver from 'file-saver';
import { manifestReconcileMatch, manifestReconcileResult } from '../insurgo-interfaces/manifest-reconcile-result';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ManifestItemsDisplayComponent } from '../insurgo-ui-components/manifest-items-display/manifest-items-display.component';
import { throwError } from 'rxjs';

interface userReconcileResult { 
  isResultAccepted: boolean; 
  isManifestSatisfied: boolean; 
  results: { noMatch: number; partialMatch: number; missing: number; };
}


@Injectable({
  providedIn: 'root'
})
export class ManifestService {

  apiUrl = environment.apiUrl;
  private dialogRef: NbDialogRef<any>;
  constructor(
    public authHttp: HttpClient,
    private readonly logger: LoggerService,
    private errorService: ErrorHandlingService,
    private dialogService: NbDialogService
  ) { }


  async processReconcileResult(reconcileResult: manifestReconcileResult[], overrideOption = false): Promise<userReconcileResult> {

    const noMatch: manifestReconcileResult[] = [];
    const partialMatch: manifestReconcileResult[] = [];
    const missingTapes: manifestReconcileResult[] = [];

    reconcileResult.forEach(result => {
      if (result.fullMatch === manifestReconcileMatch.NO_MATCH) {
        noMatch.push(result);
      } else if (result.fullMatch === manifestReconcileMatch.PARTIAL_MATCH) {
        partialMatch.push(result);
      } else if (result.fullMatch === manifestReconcileMatch.MISSING_TAPE) {
        missingTapes.push(result);
      }
    });

    if (noMatch.length > 0 || partialMatch.length > 0 || missingTapes.length > 0) {
      this.dialogRef = this.dialogService.open(ManifestItemsDisplayComponent, {
        closeOnBackdropClick: true,
        context: {
          noMatch: noMatch,
          partialMatch: partialMatch,
          missingTapes: missingTapes,
          closeKit: overrideOption
        }
      });

      return new Promise<userReconcileResult>((resolve) => {
        this.dialogRef.onClose.subscribe((result) => {
          if (result === 'override') {
            resolve({
              isResultAccepted: true, isManifestSatisfied: false, results:
                { noMatch: noMatch.length, partialMatch: partialMatch.length, missing: missingTapes.length }
            });
          } else {
            resolve({
              isResultAccepted: false, isManifestSatisfied: false, results:
                { noMatch: noMatch.length, partialMatch: partialMatch.length, missing: missingTapes.length }
            });
          }
        });
      });
    }
    else {
      return { isResultAccepted: true, isManifestSatisfied: true, results: { noMatch: 0, partialMatch: 0, missing: 0 } };
    }
  }


  kitManifestSummary(jobID: number): Observable<{ match: manifestReconcileMatch, tapes: string[] }[]> {
    return this.reconcileKitManifest(jobID).pipe(
      map(manifestResults => {
        console.log(manifestResults);
        const splitResults = [{
          match: manifestReconcileMatch.NO_MATCH,
          tapes: []
        },
        {
          match: manifestReconcileMatch.FULL_MATCH,
          tapes: []
        },
        {
          match: manifestReconcileMatch.PARTIAL_MATCH,
          tapes: []
        },
        {
          match: manifestReconcileMatch.MISSING_TAPE,
          tapes: []
        }]

        for (const res of manifestResults) {
          if (res.fullMatch === manifestReconcileMatch.NO_MATCH) {
            splitResults[0].tapes.push(res.sentTape.barcode);
          } else if (res.fullMatch === manifestReconcileMatch.FULL_MATCH) {
            splitResults[1].tapes.push(res.matchedTape.barcode);
          } else if (res.fullMatch === manifestReconcileMatch.PARTIAL_MATCH) {
            splitResults[2].tapes.push(res.matchedTape.barcode);
          } else if (res.fullMatch === manifestReconcileMatch.MISSING_TAPE) {
            splitResults[3].tapes.push(res.matchedTape.barcode);
          }
        }

        // do some magic
        return splitResults;
      }),
      catchError(err => {
        console.error(err);
        return throwError(err); // Emit the error to the subscribers
      })
    );
  }

  reconcileKitManifest(jobID: number): Observable<manifestReconcileResult[] | undefined> {
    const URL = `${this.apiUrl}/job/manifest-reconcile/${jobID}`;

    return this.authHttp.get<manifestReconcileResult[] | undefined>(URL).pipe(
      catchError((error) => {
        return this.errorService.handleError(error);
      }),
    );
  }

  assetTrackReconcileTapeOnManifest(jobID: number, tapeBarcode: string, tapeSerial: string, cmSerial: string): Observable<manifestReconcileResult | undefined> {
    const URL = `${this.apiUrl}/job/manifest-reconcile/asset-track`;

    const body = {
      jobID,
      cmSerial,
      barcode: tapeBarcode,
      tapeSerial
    }
    return this.authHttp.post<manifestReconcileResult | undefined>(URL, body).pipe(
      catchError((error) => {
        return this.errorService.handleError(error);
      }),
    );
  }

  updateKitManifest(jobID: number, manifest: File): Observable<void> {
    const URL = `${this.apiUrl}/job/manifest`;

    const formData = new FormData();
    // Append all form values to formData   
    formData.append('jobID', jobID.toString());
    formData.append('manifestFile', manifest);

    return this.authHttp.patch<void>(URL, formData).pipe(
      catchError((error) => {
        return this.errorService.handleError(error);
      }),
    );
  }


  removeKitManifest(jobID: number): Observable<void> {
    const URL = `${this.apiUrl}/job/manifest-removal`;

    const body = { jobID: jobID };

    return this.authHttp.patch<void>(URL, body).pipe(
      catchError((error) => {
        return this.errorService.handleError(error);
      }),
    );
  }

  downloadManifestTemplate(): Observable<any> {
    const URL = `${this.apiUrl}/manifest/template`;
    return this.authHttp.get(URL, { responseType: 'blob', observe: 'response' }).pipe(
      map((response: any) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition.split(';')[1].split('=')[1].replace(/"/g, ''); // Extract the filename from the Content-Disposition header
        const blob = new Blob([response.body], { type: response.body.type });

        FileSaver.saveAs(blob, filename);
      }),
      catchError((error) => {
        return this.errorService.handleError(error);
      }),
    );
  }

}
