import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError, finalize, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoggerService } from './logging/logger.service';
import { PostalAddress } from '../insurgo-interfaces/address';

@Injectable()
export class AddressService {

  apiUrl = environment.apiUrl;

  constructor(
    public authHttp: HttpClient,
    private readonly logger: LoggerService
  ) { }

  getAllAddresses(showHidden:boolean): Observable<PostalAddress[]> {
    const URL = `${this.apiUrl}/address/`;
    const params = {listHidden: showHidden.toString()};
    return this.authHttp.get<PostalAddress[]>(URL, {params})
      .pipe(
        catchError(this.handleError('getAllAddresses', URL))
      );
  }

  getAddressesByCompanyID(companyID:number, showHidden:boolean): Observable<PostalAddress[]> {
    const URL = `${this.apiUrl}/company/${companyID}/address`;
    const params = {listHidden: showHidden.toString()};
    return this.authHttp.get(URL, {params})
      .pipe(
        catchError(this.handleError('getAddressesByCompanyID', URL))
      );
  }

  getAddressByID(addressID: number): Observable<PostalAddress>{
    const URL = `${this.apiUrl}/address/${addressID}`;
    return this.authHttp.get(URL)
      .pipe(
        catchError(this.handleError('getAddressByID', URL))
      );
  }

  updateAddress( address: PostalAddress): Observable<any> {
    const URL = `${this.apiUrl}/address/${address.addressID}`;
    return this.authHttp.put(URL, address)
      .pipe(
        catchError(this.handleError('updateAddress', URL))
      );
  }

  insertAddress( address: PostalAddress): Observable<any> {
    const URL = `${this.apiUrl}/address/`;
    return this.authHttp.post(URL, address)
      .pipe(
        catchError(this.handleError('insertAddress', URL))
      );
  }

  deleteAddress(addressID: number): Observable<any>  {
    const URL = `${this.apiUrl}/address/${addressID}`;
    return this.authHttp.delete(URL)
      .pipe(
        catchError(this.handleError('deleteAddress-hmi', URL))
      );
  }


  private handleError(operation: String, url: String): any {
    return (err: any) => {
        const errMsg = `error in ${operation}() retrieving ${url}`;
        this.logger.error(`${errMsg}:`, err);
        if (err instanceof HttpErrorResponse) {
            // you could extract more info about the error if you want, e.g.:
            // console.log(`status: ${err.status}, ${err.statusText}`);
        }
        return ErrorObservable.create(errMsg);
    };
  }
}
