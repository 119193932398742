import { Component, OnInit, OnDestroy } from '@angular/core';
import { KitService } from '../../../insurgo-services/kit.service';
import { JobType, Kit } from '../../../insurgo-interfaces/kit';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationService } from '../../../insurgo-services/configuration.service';
import { ManifestService } from '../../../insurgo-services/manifest.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastService } from '../../../insurgo-services/toast.service';

@Component({
  selector: 'ngx-create-kit',
  templateUrl: './create-kit.component.html',
  styleUrls: ['./create-kit.component.scss']
})
export class CreateKitComponent implements OnInit, OnDestroy {

  public createJobForm: FormGroup;
  public formError: any = {
    submitting: false,
    error: false,
    success: false
  };

  public get companyID(): number {
    return this.createJobForm.controls['companyID'].value;
  };

  public get lastKitNumber(): string {
    return this.createJobForm.controls['jobNumber'].value;
  }

  public auditFunctionalityEnabled: boolean = false;
  public sendAllFunctionalityEnabled: boolean = false;
  public manifestFunctionalityEnabled: boolean = false;
  public hideManifestInput: boolean = false;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly _kitService: KitService,
    private configurationService: ConfigurationService,
    private formBuilder: FormBuilder,
    private manifestService: ManifestService,
    private toastService: ToastService
  ) {
    this.createJobForm = this.formBuilder.group({
      addressID: [0, [Validators.required, Validators.min(1)]],
      companyID: [0, Validators.required],
      jobTypeID: [0, [Validators.required, Validators.min(1)]],
      internalJobNumber: [''],
      jobNumber: ['', Validators.required],
      manifestFile: [null],
      manifestFileName: [''],
    })

    if (!this.auditFunctionalityEnabled && !this.sendAllFunctionalityEnabled) {
      this.createJobForm.controls['jobTypeID'].setValue(JobType.STANDARD);
    }

    this.createJobForm.get('manifestFileName').disable();

    this.createJobForm
      .get('companyID')
      .valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async (selectedValue) => {
        if (selectedValue) {
          if (selectedValue !== this.companyID) {
            this.createJobForm.get('addressID').setValue(0);
          }
        }
      })

    this.createJobForm
      .get('jobTypeID')
      .valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async (selectedValue) => {
        if (selectedValue === JobType.TAPE_AUDIT) {
          this.createJobForm.get('manifestFile').setValue(null);
          this.createJobForm.get('manifestFileName').setValue('');
          this.hideManifestInput = true;
        } else {
          if (this.manifestFunctionalityEnabled) this.hideManifestInput = false;
        }
      })
  }

  public ngOnInit(): void {
    if (this.configurationService.getConfigurationSettings().auditAllowed) {
      this.auditFunctionalityEnabled = true;
    }
    if (this.configurationService.getConfigurationSettings().sendAllAllowed) {
      this.sendAllFunctionalityEnabled = true;
    }
    if (this.configurationService.getConfigurationSettings().manifestAllowed) {
      this.manifestFunctionalityEnabled = true;
    } else {
      this.hideManifestInput = true;
    }
    this.getLastKitNumber();
  }

  public ngOnDestroy(): void {
    // Unsubscribes from all child subscriptions
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public reset(): void {
    this.createJobForm.reset();
    if (!this.auditFunctionalityEnabled && !this.sendAllFunctionalityEnabled) {
      this.createJobForm.controls['jobTypeID'].setValue(JobType.STANDARD);
    } else {
      this.createJobForm.controls['jobTypeID'].setValue(0);
    }
    this.getLastKitNumber();
  }

  public submit(): void {
    this.formError.submitting = true;

    this.createJobForm.disable();

    this.formError.success = false;
    this.formError.error = false;

    const jobNumber = this.createJobForm.controls['jobNumber'].value;
    const addressID = this.createJobForm.controls['addressID'].value;
    const companyID = this.createJobForm.controls['companyID'].value;
    const internalJobNumber = this.createJobForm.controls['internalJobNumber'].value;
    const jobTypeID = this.createJobForm.controls['jobTypeID'].value;
    const manifest = this.createJobForm.controls['manifestFile'].value;


    this._kitService.saveNewKit(jobNumber, addressID, companyID, internalJobNumber, jobTypeID, manifest).subscribe((result) => {
      this.formError.success = true;
      setTimeout(function () {
        this.formError.submitting = false;
        this.formError.success = false;
        this.createJobForm.enable();
        this.createJobForm.get('manifestFileName').disable();
        this.reset(this.createKitForm);
      }.bind(this), 3000);
    },
      (err) => {
        console.log(err);
        this.formError.error = true;
        this.toastService.showToast('danger', 'create error', err);
        setTimeout(function () {
          this.formError.submitting = false;
          this.createJobForm.enable();
          this.createJobForm.get('manifestFileName').disable();
        }.bind(this), 3000);
      })
  }


  public onFileChange(event: Event): void {
    if (event && event.target) {
      if ((event.target as HTMLInputElement).files as FileList && (event.target as HTMLInputElement).files!.length) {
        const file = (event.target as HTMLInputElement).files ? (event.target as HTMLInputElement).files![0] : null;
        this.createJobForm.patchValue({
          manifestFile: file,
          manifestFileName: file.name
        });
      }
    }
  }

  public downloadTemplate(): void {
    this.manifestService.downloadManifestTemplate().subscribe({
      next: () => {
        this.toastService.showToast(
          'success',
          'Template downloaded',
          ``,
          'checkmark-outline'
        );
      },
      error: (err) => {
        console.log(`manifest download error: ${err}`);
        this.toastService.showToast(
          'danger',
          'Failed to download',
          `An error occurred trying to download the manifest template`,
          'alert-triangle-outline'
        );
      }
    })
  }

  private getLastKitNumber(): void {
    this._kitService.getLastKitNumber().subscribe({
      next: (data: Kit) => {
        let lastNumber = parseInt(data.kitNumber);
        lastNumber = lastNumber + 1;
        this.createJobForm.controls['jobNumber'].setValue('' + lastNumber);

      },
      error: (err) => {
        console.log(`manifest download error: ${err}`);
        this.toastService.showToast(
          'danger',
          'Failed to generate number',
          `An error occurred trying to generate the next job number`,
          'alert-triangle-outline'
        );
      }
    })
  }
}
