import { Component, Input, OnInit } from '@angular/core';
import { manifestReconcileResult } from '../../insurgo-interfaces/manifest-reconcile-result';
import { NbDialogRef } from '@nebular/theme';
import { ExineAgGrid } from '../../insurgo-classes/exine-ag-grid';

@Component({
  selector: 'ngx-manifest-items-display',
  templateUrl: './manifest-items-display.component.html',
  styleUrls: ['./manifest-items-display.component.scss']
})
export class ManifestItemsDisplayComponent implements OnInit {
  @Input() missingTapes: manifestReconcileResult[] = [];
  @Input() noMatch: manifestReconcileResult[] = [];
  @Input() partialMatch: manifestReconcileResult[] = [];
  @Input() closeKit: boolean = false;
  reconcileTable = new ExineAgGrid();


  cmSerialExist = false;
  tapeSerialExist = false;
  barcodeExist = false;

  constructor(
    protected ref: NbDialogRef<ManifestItemsDisplayComponent>,
  ) {
   
    const columnDefs = [
      { headerName: 'Result', field: 'matchResult', minWidth: 120, flex: 1, sortable: true },
      { headerName: 'Barcode', field: 'barcode', minWidth: 120, flex: 1, sortable: true },
      { headerName: 'CM Serial', field: 'cmSerial', minWidth: 120, flex: 1, sortable: true },
      { headerName: 'Tape Serial', field: 'tapeSerial', minWidth: 120, flex: 1, sortable: true },
    ];
    this.reconcileTable.columnDefs = columnDefs;

  }

  ngOnInit(): void {
    this.noMatch.forEach((item) => {
      if (item.sentTape.barcode) {
        this.barcodeExist = true;
      }
      if (item.sentTape.serial) {
        this.tapeSerialExist = true;
      }
      if (item.sentTape.cm) {
        this.cmSerialExist = true;
      }
      this.reconcileTable.rowData.push({
        matchResult: 'unexpected tape',
        barcode: item.sentTape.barcode,
        cmSerial: item.sentTape.cm,
        tapeSerial: item.sentTape.serial,
      })
    });

    this.partialMatch.forEach((item) => {
      if (item.sentTape.barcode) {
        this.barcodeExist = true;
      }
      if (item.sentTape.serial) {
        this.tapeSerialExist = true;
      }
      if (item.sentTape.cm) {
        this.cmSerialExist = true;
      }
      this.reconcileTable.rowData.push({
        matchResult: 'partial match',
        barcode: item.sentTape.barcode,
        cmSerial: item.sentTape.cm,
        tapeSerial: item.sentTape.serial,
      })
    });

    this.missingTapes.forEach((item) => {
      if (item.matchedTape.barcode) {
        this.barcodeExist = true;
      }
      if (item.matchedTape.serial) {
        this.tapeSerialExist = true;
      }
      if (item.matchedTape.cm) {
        this.cmSerialExist = true;
      }
      this.reconcileTable.rowData.push({
        matchResult: 'missing tape',
        barcode: item.matchedTape.barcode,
        cmSerial: item.matchedTape.cm,
        tapeSerial: item.matchedTape.serial,
      })
    });
  
  }

  onGridReady(params) {
    this.reconcileTable.gridApi = params.api;
    this.reconcileTable.columnApi = params.columnApi;

    if (!this.cmSerialExist) {
      this.reconcileTable.columnApi.setColumnVisible('cmSerial', false);
    }
    if (!this.barcodeExist) {
      this.reconcileTable.columnApi.setColumnVisible('barcode', false);
    }
    if (!this.tapeSerialExist) {
      this.reconcileTable.columnApi.setColumnVisible('tapeSerial', false);
    }
  }

  confirm(): void {
    this.ref.close('');
  }

  overrideConfirm(): void {
    this.ref.close('override');
  }

  csvExport() {
    const fileName = `manifest_errors`;
    this.reconcileTable.gridApi.exportDataAsCsv({ fileName: fileName });
  }


}
