<nb-card>
  <nb-card-header>
    Set Tape Value
  </nb-card-header>
  <nb-card-body>
    {{currentValuation.tapeModel}} -
    {{currentValuation.tapeManufacturer}}
    <div class="row">
      <div class="col-12">
        <nb-radio-group [(ngModel)]="selectedOption">
          <nb-radio *ngFor="let option of options" [checked]="option.checked" [disabled]="option.disabled"
            [value]="option.value">
            {{ option.label }}
          </nb-radio>
        </nb-radio-group>
      </div>
    </div>
    <nb-card-footer class="clear-fix">
      <div class="row">
        <div class="col-6">
          <button nbButton size="small" class="float-center" status="danger" (click)="removeValue()">Remove
            <nb-icon icon="trash-2-outline"></nb-icon>
          </button>
        </div>
        <div class="col-6">
          <button nbButton size="small" class="float-center" status="success" (click)="updateValue()">Update
            <nb-icon icon="save-outline"></nb-icon>
          </button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card-body>
</nb-card>