import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
// <div class="btn-group btn-group-justified" role="group">
// <div class="btn-group" role="group" *ngFor = "let button of buttons">
//     <button nbButton [status]="button.class" size="tiny" (click)="onClick(button.id)"><nb-icon [icon]=button.icon></nb-icon>{{button.label}}</button>
// </div>
// </div>
@Component({
    selector: 'child-cell',
    template: `
                <ng-container *ngFor = "let button of buttons">
                    <ng-container *ngIf = "button.tooltip">
                        <button nbButton ghost type="button" [nbTooltip]="button.tooltip" [nbTooltipStatus]="button.class" [status]="button.class"  size="tiny" (click)="onClick(button.id)"><nb-icon [icon]=button.icon></nb-icon>{{button.label}}</button>            
                    </ng-container>
                    <ng-container *ngIf = "!button.tooltip">
                        <button nbButton ghost  type="button"  [status]="button.class"  size="tiny" (click)="onClick(button.id)"><nb-icon [icon]=button.icon></nb-icon>{{button.label}}</button>            
                    </ng-container>
                </ng-container>
           
        `
})

// EXAMPLE USAGE
// {headerName: 'Actions', width: 150, filter: false, sortable: false,
// cellRenderer: 'buttonRenderer',
// cellRendererParams: {
//   onClick: this.showAccountModal.bind(this),
//   buttons: [
    // {id: 'view', label: '', class: 'btn-primary mr-1', icon: 'fas fa-eye'},
    // {id: 'delete', label: 'Delete', class: 'btn-warning'}],
    // {id: 'delete', label: '', class: 'danger', icon: 'trash-2-outline',show: this.buttonShowDecider}
// }}
// buttonShowDecider(data){
//     if(data.addressID > -1) return true;
//     return false;
//   }

export class ButtonRendererComponent implements ICellRendererAngularComp {

    public buttons: any;
    public params: any;

    agInit(params): void {
        this.params = params;
        this.buttons = [];
        for (let index = 0; index < this.params.buttons.length; index++) {
            if('show' in this.params.buttons[index]){
                if(this.params.buttons[index].show(params.data)) this.buttons.push(this.params.buttons[index]);    
            } else {
                this.buttons.push(this.params.buttons[index])
            }
        }
    }

    refresh(): boolean {
        return false;
    }


    onClick(btnLbl) {
        if (this.params.onClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                button: btnLbl,
                rowData: this.params.node.data
                // ...something
            }
            this.params.onClick(params);

        }
    }
}