import { Component, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-confirmation-modal',
  template: `
            <form #genericForm="ngForm">
              <nb-card style="min-width: 375px;">
                <nb-card-header>
                  {{title}}
                </nb-card-header>
                <nb-card-body>
                  {{bodyMessage}}
                  <div class="form-group mt-4">
                    <input [autoFocus] (keypress)="keyPress($event)" #genericInput  *ngIf="!multiLine" [status]="displayFieldCss(genericForm)" [(ngModel)]="result" type="text" nbInput fullWidth required  id="genericInput" name="genericInput">
                    <textarea [autoFocus] (keypress)="keyPress($event)" #genericInput  rows="8" [status]="displayFieldCss(genericForm)" [(ngModel)]="result" *ngIf="multiLine" type="text" nbInput fullWidth required  id="genericInput" name="genericInput"></textarea>
                  </div>
                </nb-card-body>
                <nb-card-footer class="clear-fix">
                  <button nbButton outline status="success" class="float-right" [disabled]="genericForm.invalid" (click)="confirm()">{{confirmButtonText}}</button>
                  <button *ngIf="cancelButtonText.length > 0" nbButton outline status="danger" class="float-left" (click)="decline()">{{cancelButtonText}}</button>
                </nb-card-footer>
              </nb-card>
            </form>
      `  
})


export class BasicInputModalComponent implements AfterViewInit {
  @Input() title: string;
  @Input() bodyMessage: string;
  @Input() confirmButtonText: string;
  @Input() cancelButtonText: string;
  @Input() multiLine: boolean;
  constructor(protected ref: NbDialogRef<BasicInputModalComponent>) { }

  @ViewChild('genericForm') addUserForm: NgForm;
  @ViewChild('genericInput') inputBox: ElementRef;

  result = '';
  ngAfterViewInit(){
    this.inputBox.nativeElement.focus();
  }
  confirm(): void {
    this.ref.close(this.result);
  }

  decline(): void {
    this.ref.close('decline');
  }

  cancel(): void {
    this.ref.close('cancel');
  }
  
  keyPress(event: any) {
    //keycode 13 is enter key
    if (event.keyCode === 13 && this.result.length > 0) {    
      this.confirm()
    }
  }

  displayFieldCss(form: NgForm) {
    if (form.dirty) {
      if (form.invalid) {
        //invalid
        return 'danger';
      } else {
        //valid
        return 'success';
      }
    } else {
      return 'basic';
    }
  }
}