import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorObservable } from 'rxjs-compat/observable/ErrorObservable';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoggerService } from './logging/logger.service';
import { NbToastrService, NbGlobalPhysicalPosition} from '@nebular/theme';
import { Howl } from 'howler'

export interface Configuration {
  default: boolean;
  auditAllowed: boolean;
  sendAllAllowed: boolean;
  manifestAllowed: boolean;
  isDrivesUsageTableBeingUsed: boolean;
  isGenerateCertificatesEnabled: boolean;
}

interface ConfigurationDatabase {
  auditEnabled: number;
  sendAllTapeEnabled: number;
  manifestEnabled: number;
  drivesUsageTableEnabled: number;
  generateCertificatesEnabled: number;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  apiUrl = environment.apiUrl;
  siteConfiguration: Configuration;

  constructor(
    public authHttp: HttpClient,
    private readonly logger: LoggerService,
    private toastrService: NbToastrService
  ) { }

  public successMessageTimeOut = 1500;
  public successSnd = new Howl({
    src: ['../../assets/sound/success.wav']
  });
  public errorSnd = new Howl({
    src: ['../../assets/sound/alert.wav']
  });

  getConfigurationSettings(): Configuration {
    if (this.siteConfiguration) {
      return this.siteConfiguration;
    }
    throw new Error('site not configured');
  }

  setConfigurationSettings(){
    return this.getDatabaseModel().toPromise().then(async config => {
      // await new Promise(resolve => setTimeout(resolve, 5000));
      this.siteConfiguration = {
        default: false,
        auditAllowed: config.auditEnabled === 1 ? true : false,
        sendAllAllowed: config.sendAllTapeEnabled === 1 ? true : false,
        manifestAllowed: config.manifestEnabled === 1 ? true : false,
        isDrivesUsageTableBeingUsed: config.drivesUsageTableEnabled === 1 ? true : false,
        isGenerateCertificatesEnabled: config.generateCertificatesEnabled === 1 ? true : false
      }
      console.log('Configuration loaded');
    }, async error =>{
      this.siteConfiguration = {
        default: true,
        auditAllowed: false,
        sendAllAllowed: false,
        manifestAllowed: true,
        isDrivesUsageTableBeingUsed: false,
        isGenerateCertificatesEnabled: false
      }
      console.log('Unable to configure site, use defaults...');
    });
  }

  playErrorSound(times: number, delay: number): void {
    if (times <= 0) {
        return;
    }
    this.errorSnd.play();
    setTimeout(() => {
        this.playErrorSound(times - 1, delay);
    }, delay);
}

  private getDatabaseModel(): Observable<ConfigurationDatabase> {
    const URL = `${this.apiUrl}/configuration`;

    return this.authHttp.get<ConfigurationDatabase>(URL)
      .pipe(
        catchError(this.handleError('getDatabaseModel', URL))
      );
  }

  private handleError(operation: String, url: String): any {
    return (err: any) => {
      const errMsg = `error in ${operation}() retrieving ${url}`;
      this.logger.error(`${errMsg}:`, err);
      if (err instanceof HttpErrorResponse) {
        // you could extract more info about the error if you want, e.g.:
        // console.log(`status: ${err.status}, ${err.statusText}`);
      }
      return ErrorObservable.create(errMsg);
    };
  }
}
