import { Injectable } from '@angular/core';
import { TapeValuation } from '../../../insurgo-interfaces/tape-value';
import { NbDialogService } from '@nebular/theme';
import { SetTapeValueComponent } from './set-tape-value.component';

@Injectable()
export class SetTapeValueService {

  constructor(
    private dialogService: NbDialogService
  ) { }

  show(_currentValuation: TapeValuation) {

    const modal = this.dialogService.open(SetTapeValueComponent, {
      closeOnBackdropClick: true,
      context: {
        currentValuation: _currentValuation
      }
    });
    return new Promise<string>((resolve, reject) => {
      modal.onClose.subscribe((result) => {
        resolve(result);
      });
    });

  }
}
