import { Component, OnInit } from '@angular/core';
import { ButtonRendererComponent } from '../../../insurgo-services/cellRenderers/button.renderer.component';
import { CompanyService } from '../../../insurgo-services/company.service';
import { Company } from '../../../insurgo-interfaces/company';
import { ModalConfirmationService } from '../../../insurgo-services/modalTemplates/confirmation-modal/confirmation-modal.service';
import { ViewCompanyService } from '../../../insurgo-services/modalTemplates/viewCompany/viewCompany.service';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from '@nebular/theme';

@Component({
  selector: 'ngx-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  public columnDefs;
  public context;
  public frameworkComponents;
  public defaultColDef;
  public gridOptions;
  constructor( 
    private readonly _companyService: CompanyService,
    private _modalConfirmationService: ModalConfirmationService,
    private toastrService: NbToastrService,
    private _viewCompanyService: ViewCompanyService,

  ) { 
    this.gridOptions = {
      onRowDoubleClicked: this.rowDoubleClick.bind(this)
    }
    this.frameworkComponents = {
        buttonRenderer: ButtonRendererComponent,
    };
    this.columnDefs = [
        {headerName: 'Company Name', field: 'companyName', minWidth: 120, flex:1, sortable: true, sort: 'asc', tooltipField: "companyName" },
        {headerName: 'Address', field: 'address.addressString', minWidth: 120, flex:2, tooltipField: "address.addressString"},
        {headerName: 'Contact Name', field: 'contactName', minWidth: 120, flex:1, tooltipField: "contactName" },
        {headerName: 'Email', field: 'email', minWidth: 120, flex:1, tooltipField: "email"  },
        {headerName: 'Phone Number', field: 'address.phoneNumber', minWidth: 120, flex:1, tooltipField: "address.phoneNumber"},
        {headerName: 'Actions', minWidth: 120, maxWidth:120, filter: false, sortable: false,
          cellRenderer: 'buttonRenderer',
          cellRendererParams: {
            onClick: this.showAccountModal.bind(this),
            buttons: [{id: 'view', label: '', class: 'primary', icon: 'eye-outline'}, {id: 'delete', label: '', class: 'danger', icon: 'trash-2-outline'}]
          }},
    ];
    this.context = { componentParent: this };
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      filter: true,
      resizable: true,
    };
}

  clients:Company[];

  ngOnInit() {
    this.reloadCompanies();
  }

  reloadCompanies(){
    this._companyService.getCompanies().subscribe(
      (companies: Company[]) => {
        this.clients = companies.map(i => {
          i.address.addressString = `${i.address.line1 + ','} ${(i.address.line2 || '') === '' ? '': i.address.line2 + ','} ${i.address.city  + ','} ${i.address.postalCode}`;
          return i;
        });
      }, 
      (err) => {

      }
    )
  }

  private showToast(type: NbComponentStatus, title: string, body: string, iconName: string = 'alert-triangle-outline') {
    const config = {
      status: type,
      destroyByClick: false,
      duration: 4000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: true,
      icon: iconName,
      pack: 'eva' 
    };

    this.toastrService.show(
      body,
      title,
      config
    );
  }

  addCompany() {
    this._viewCompanyService.viewCompanyModal().then(
      (result) => {
        this.reloadCompanies();
      }, 
      (err) => {
        this.reloadCompanies();
      }
    );
  }

  rowDoubleClick(row){
      this._viewCompanyService.viewCompanyModal(row.data.companyID).then(
        (result) => {
          this.reloadCompanies();
        }, 
        (err) => {
          this.reloadCompanies();
        }
      );
  }

  public async showAccountModal(rowBtnClick) {
    if (rowBtnClick.button === 'view') {
      this._viewCompanyService.viewCompanyModal(rowBtnClick.rowData.companyID).then(
        (result) => {
          this.reloadCompanies();
        }, 
        (err) => {
          this.reloadCompanies();
        }
      );
    } else if (rowBtnClick.button === 'delete') {
      const result =  await this._modalConfirmationService.confirm(`${rowBtnClick.rowData.companyName}`, `Are you sure you want to delete this account?`);
      if (result === 'confirm') {
        this._companyService.deleteCompany(rowBtnClick.rowData.companyID).subscribe(
          (data) => {
            this.showToast("success","Company removed", `${rowBtnClick.rowData.companyName} was removed sucesfully!`, 'checkmark-outline')
            this.reloadCompanies();
           },
          (err) => {
            this.showToast("danger","Removal failed", `Error occurred removing ${rowBtnClick.rowData.companyName}!`, 'trash-2-outline')
            this.reloadCompanies();
          }
        );
      }
    }
  }
}
