import { Component, OnInit } from '@angular/core';
import { TapeValuation } from '../../../insurgo-interfaces/tape-value';
import { TapeHelpService } from '../../../insurgo-services/tape-helper.service';
import { ButtonRendererComponent } from '../../../insurgo-services/cellRenderers/button.renderer.component';
import { SetTapeValueService } from '../../../insurgo-services/modalTemplates/set-tape-value/set-tape-value.service';
import { NbToastrService, NbComponentStatus, NbGlobalPhysicalPosition } from '@nebular/theme';
import { FormGroup, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'ngx-tape-value',
  templateUrl: './tape-value.component.html',
  styleUrls: ['./tape-value.component.scss']
})
export class TapeValueComponent implements OnInit {

  activeValuations: TapeValuation[] = [];
  columnDefs;
  frameworkComponents;
  defaultColDef;
  context;
  gridOptions;
  newValuationForm = new FormGroup({
    tapeType: new FormControl(null, Validators.required),
    tapeManuf: new FormControl(null, Validators.required),
    initialValuation: new FormControl(null, Validators.required)
  });

  tapeTypeOptions: any[] = [];
  tapeManufacturerOptions: any[] = [];
  tapeValueOptions: any[] = [];

  constructor(
    private readonly _tapeHelpService: TapeHelpService,
    private readonly _setTapeValueService: SetTapeValueService,
    private readonly toastrService: NbToastrService
  ) {

    this.gridOptions = {
      onRowDoubleClicked: this.onRowDoubleClicked.bind(this),
      tooltipShowDelay: 0,
      enableCellChangeFlash: true,
    }
    this.columnDefs = [
      { headerName: 'Model Type', field: 'tapeModel', flex: 1, minWidth: 120, },
      { headerName: 'Manufacturer', field: 'tapeManufacturer', flex: 1, minWidth: 120, },
      {
        headerName: 'Value', field: 'tapeValue', flex: 1, minWidth: 120,
      }
    ]
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.context = { componentParent: this };
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      filter: true,
      resizable: true
    };


  }


  ngOnInit(): void {
    this.getValuations();
    this.getTapeTypes();
    this.getTapeManufacturers();
    this.tapeValueOptions = [
      { value: 'valuable' }, { value: 'not-valuable' }
    ]

  }

  private async getTapeTypes() {
    this.tapeTypeOptions = await this._tapeHelpService.getAllModels().toPromise();
    //console.log(this.tapeTypeOptions);
  }
  private async getTapeManufacturers() {
    this.tapeManufacturerOptions = [{ tapeManufacturerID: 0, manufacturerName: 'Any' }]
    this.tapeManufacturerOptions = this.tapeManufacturerOptions.concat(await this._tapeHelpService.getAllManufacturers().toPromise());
    //console.log(this.tapeManufacturerOptions);
  }

  async getValuations() {

    let _activeValuations = await this._tapeHelpService.getAllTapeValuations();
    _activeValuations.sort((a, b) => (a.tapeModel > b.tapeModel) ? 1 : (a.tapeModel === b.tapeModel) ? ((a.tapeManufacturer > b.tapeManufacturer) ? 1 : -1) : -1)
    this.activeValuations = _activeValuations;
  }

  async saveNewValuation() {
    try {
      let manufID = this.newValuationForm.controls['tapeManuf'].value
      const modelID = this.newValuationForm.controls['tapeType'].value
      const valuation = this.newValuationForm.controls['initialValuation'].value

      if (this.conflictingValuation(manufID, modelID)) {
        this.showToast('danger', 'alert-circle-outline', 'Valuation Conflict', `A value already exists for this model and manufacturer`);

      } else {
        await this._tapeHelpService.createTapeValuation(valuation, modelID, manufID).toPromise();
        this.showToast('success', 'checkmark-outline', 'Value Added', `The new value has been saved, the valuations table will now refresh`);
        this.getValuations();
      }


    } catch (error) {
      this.showToast('danger', 'alert-circle-outline', 'Entry Failed', `An error occurred trying to add a new Tape's value`);

      console.log(error);
    }

  }

  conflictingValuation(manufID, modelID) {
    let conflict = false;
    if (manufID === 0) {
      manufID = null;
    }
    this.activeValuations.forEach(valuation => {
      if (valuation.tapeModelID === modelID && valuation.tapeManufacturerID === manufID) {
        conflict = true;
      }
    });
    return conflict;
  }
  onRowDoubleClicked(row) {
    const thisValuation = this.activeValuations[row.rowIndex];
    this._setTapeValueService.show(thisValuation).then(
      async (result) => {
        if (result) {
          try {
            if (result === 'remove') {
              await this._tapeHelpService.removeTapeValuation(thisValuation.tapeValueID).toPromise();
              this.showToast('success', 'checkmark-outline', 'Value Removed', `Tape's value has been removed`);
              this.gridOptions.rowData.splice(row.rowIndex, 1)
              this.gridOptions.api.setRowData(this.gridOptions.rowData)
            } else if (result === 'cancel') {
              // do nothing
            } else {
              await this._tapeHelpService.editTapeValuation(thisValuation.tapeValueID, result).toPromise();
              this.showToast('success', 'checkmark-outline', 'Value Updated', `Tape's value has successfully been updated`);
              this.activeValuations[row.rowIndex].tapeValue = result;
              this.refreshRow(row.rowIndex);
              console.log(result);
            }
          } catch (error) {
            this.showToast('danger', 'alert-circle-outline', 'Updated Failed', `An error occurred trying to update the Tape's value`);
            console.log(error)
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  refreshRow(rowIndex) {
    const rowNode = this.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    var params = {
      force: true,
      suppressFlash: false,
      rowNodes: [rowNode],
    };
    var millis = 750;

    setTimeout(() => {
      this.gridOptions.api.refreshCells(params);
    }, millis);

  }


  showToast(status: string, icon: string, heading: string, body: string) {
    const config = {
      status: status as NbComponentStatus,
      destroyByClick: false,
      duration: 2000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: true,
      icon: icon,
      pack: 'eva'
    };

    this.toastrService.show(
      heading,
      body,
      config
    );
  }
}
