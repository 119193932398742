import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef, NbToastrService, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { TapeValuation } from '../../../insurgo-interfaces/tape-value';
import { ModalConfirmationService } from '../confirmation-modal/confirmation-modal.service';

@Component({
  selector: 'ngx-view-single-tape-analysis-value',
  templateUrl: './view-single-tape-analysis.component.html',
  styleUrls: ['./view-single-tape-analysis.component.scss']
})
export class ViewSingleTapeAnalysisComponent implements OnInit {

  constructor(
    protected ref: NbDialogRef<ViewSingleTapeAnalysisComponent>,
    private readonly toastrService: NbToastrService,
    private _modalConfirmationService: ModalConfirmationService
  ) { }

  @Input() tapeAnalysis: any;
  
  decline(): void {
    this.ref.close('decline');
  }

  cancel(): void {
    this.ref.close('cancel');
  }

  ngOnInit(): void {
  
  }

}
