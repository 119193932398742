import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import counties from '../../../assets/json/counties.json';


@Component({
  selector: 'app-county-selector',
  template: `<ng-select
                  [(ngModel)]  ='_selectedData'
                  [items] = '_counties'
                  (change) = "change($event)"
                  (open) = "touch()"
                  placeholder = "No County Selected..."
                  bindLabel = "name"
                  bindValue = "name"
                  notFoundText = "No counties found..."
                  [disabled] = "_disabled"
                  labelForId = "countySelector"
                  appendTo="body"
              >
              </ng-select>
        `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountySelectorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CountySelectorComponent),
      multi: true
    }
  ]
})

export class CountySelectorComponent implements ControlValueAccessor, Validator {
  constructor(
  ) { }

  @Input() required = false; // default required as false
  @Input() name;
  _selectedData = '';
  _invalid = true; // always invalid at the start
  _disabled = false;
  _counties = counties;
  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  touch() {
    this.propagateTouch(null);
  }

  validate(c: FormControl) {
    if (this.required) {
      return (!this._invalid) ? null : {required: {valid: false}};
    } else {
      return null;
    }
  }

  writeValue(value: any) {
    this._selectedData = value;
    if (!value) {
      this._invalid = true;
    } else {
      this._invalid = false;
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  change(_newData): void {
    if (!_newData) {
      this._invalid = true;
      this.propagateChange('');
    } else {
      this._invalid = false;
      this.propagateChange(this._selectedData);
    }
  }
}
