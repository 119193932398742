import { Component, Input, Output, OnInit, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import countries from '../../../assets/json/countries.json';

@Component({
  selector: 'app-country-selector',
  template: `<ng-select
                  [(ngModel)]="_selectedData"
                  [items] = '_countries'
                  (change) = "change($event)"
                  (open) = "touch()"
                  placeholder="No Country Selected..."
                  bindLabel = "country"
                  bindValue = "country"
                  notFoundText = "No countries found..."
                  [disabled] = "_disabled"
                  labelForId="countrySelector"
                  appendTo="body"
              >
              </ng-select>
        `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountrySelectorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CountrySelectorComponent),
      multi: true
    }
  ]
})

export class CountrySelectorComponent implements ControlValueAccessor, Validator {
  constructor() { 

  }

  @Input() required = false; // default required as false
  @Input() name;
  @Output() private onChange = new EventEmitter();
  _selectedData = '';
  _invalid = true; // always invalid at the start
  _disabled = false; // only disable if forced
  _countries = countries;
  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  touch() {
    this.propagateTouch(null);
  }

  validate(c: FormControl) {
    if (this.required) {
      return (!this._invalid) ? null : {required: {valid: false}};
    } else {
      return null;
    }
  }

  writeValue(value: any) {
    this._selectedData = value;
    if (!value) {
      this._invalid = true;
    } else {
      this._invalid = false;
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  change(_newData): void {
    if (!_newData) {
      this._invalid = true;
      this.propagateChange('');
      this.onChange.emit('');
    } else {
      this._invalid = false;
      this.propagateChange(this._selectedData);
      this.onChange.emit(this._selectedData);
    }
  }
}
