import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef, NbToastrService, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { TapeValuation } from '../../../insurgo-interfaces/tape-value';
import { ModalConfirmationService } from '../confirmation-modal/confirmation-modal.service';

@Component({
  selector: 'ngx-set-tape-value',
  templateUrl: './set-tape-value.component.html',
  styleUrls: ['./set-tape-value.component.scss']
})
export class SetTapeValueComponent implements OnInit {

  constructor(
    protected ref: NbDialogRef<SetTapeValueComponent>,
    private readonly toastrService: NbToastrService,
    private _modalConfirmationService: ModalConfirmationService
  ) { }

  @Input() currentValuation: TapeValuation;
  options = []
  selectedOption;

  result = '';
  ngAfterViewInit() {

  }

  async removeValue() {
    let res = await this._modalConfirmationService.confirm("Are you sure?", "This will delete this valuation", "OK", "Cancel", true);
    if (res === 'confirm') {
      this.ref.close('remove');
    }
  }
  updateValue() {
    console.log(`Old value: ${this.currentValuation.tapeValue}, New Value: ${this.selectedOption}`);
    if (this.selectedOption === this.currentValuation.tapeValue) {
      const config = {
        status: 'info' as NbComponentStatus,
        destroyByClick: false,
        duration: 2000,
        hasIcon: true,
        position: NbGlobalPhysicalPosition.TOP_RIGHT,
        preventDuplicates: true,
        icon: 'question-mark-circle-outline',
        pack: 'eva'
      };

      this.toastrService.show(
        'Nothing to update',
        'Warning',
        config
      );
      this.ref.close('cancel');
    } else {
      this.ref.close(this.selectedOption);
    }

  }

  decline(): void {
    this.ref.close('decline');
  }

  cancel(): void {
    this.ref.close('cancel');
  }

  ngOnInit(): void {
    this.options = [
      { value: 'valuable', label: 'Valuable' },
      { value: 'not-valuable', label: 'Not-Valuable' }
    ];
    for (let i = 0; i < this.options.length; i++) {
      const element = this.options[i];
      if (element.value === this.currentValuation.tapeValue) {
        element.checked = true;
      }
    }
    this.selectedOption = this.currentValuation.tapeValue;

  }

}
