import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { ModalConfirmationService } from '../confirmation-modal/confirmation-modal.service';
import { ViewAddressService } from '../viewAddress/viewAddress.service';
import { CompanyService } from '../../company.service'
import { AddressService } from '../../address.service'
import { NgForm } from '@angular/forms';
import { Company } from '../../../insurgo-interfaces/company';
import { ButtonRendererComponent } from '../../cellRenderers/button.renderer.component'

import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from '@nebular/theme';
import { PostalAddress } from '../../../insurgo-interfaces/address';


@Component({
  templateUrl: './viewCompany.html',

  styles: [`
    :host nb-tab {
      padding: 1.25rem;
    }
  `],
})

export class ViewCompanyModalComponent implements OnInit {
  @Input() companyID: number;
  loaded = false;
  tabState: string = '';
  viewCompanyData: Company;
  originalData: Object;

  addresses: PostalAddress[];

  formError: any = {
    submitting: false,
    error: false,
    success: false
  };

  @ViewChild('editCompanyForm') editCompanyForm: NgForm;
  
  public columnDefs;
  public context;
  public frameworkComponents;
  public defaultColDef;
  public gridOptions;

  constructor(
    protected ref: NbDialogRef<ViewCompanyModalComponent>,
    private _modalConfirmationService: ModalConfirmationService,
    private _companyService: CompanyService,
    private _addressService: AddressService,
    private toastrService: NbToastrService,
    private _viewAddressService: ViewAddressService
  ) { 
    this.gridOptions = {
      onRowDoubleClicked: this.rowDoubleClick.bind(this)
    }
    this.frameworkComponents = {
        buttonRenderer: ButtonRendererComponent,
    };
    this.columnDefs = [
        {headerName: 'Address', field: 'addressString', minWidth:120, flex:4},
        {headerName: 'Country', field: 'country', minWidth:120, flex:1},
        {headerName: 'Phone Number', field: 'phoneNumber', minWidth:120, flex:1},
        {headerName: 'Actions', filter: false, sortable: false, minWidth:120, maxWidth:120,
          cellRenderer: 'buttonRenderer',
          cellRendererParams: {
            onClick: this.rowActionClick.bind(this),
            buttons: [{id: 'view', label: '', class: 'primary', icon: 'eye-outline'}, {id: 'delete', label: '', class: 'danger', icon: 'trash-2-outline'}]
          }},
    ];
    this.context = { componentParent: this };
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      filter: true,
      resizable: true,
    };
  }
  
  async ngOnInit() {
    if (this.companyID) {
      await this.getData();
    } else {
      this.viewCompanyData = <Company>{ companyName: "", address: {country:"United Kingdom"} };
      this.loaded = true;
    }
  }

  async getData(){
    try {
      this.loaded = false;
      let data = <any> await this._companyService.getCompanyByID(this.companyID).toPromise();
      data.address.addressString = `${data.address.line1 + ','} ${(data.address.line2 || '') === '' ? '': data.address.line2 + ','} ${data.address.city  + ','} ${data.address.postalCode}`;
      
      this.viewCompanyData = <Company>this.deepCopy(<Object>data);
      this.originalData =  <Object>this.deepCopy(<Object>data);
      this.loaded = true;
    } catch (error) {
      this.showToast("danger","Fetch Error", `Error occurred getting the company data!`, 'activity');
      this.ref.close(false);
    }
  }

  async resetForm(){
    await this.getData();
    this._markFormPristine();
  }

  private _markFormPristine(): void {
    const { editCompanyForm: { value: formValueSnap } } = this; // const formValueSnap = this.myForm.value;
    this.editCompanyForm.reset(formValueSnap);
    Object.keys(this.editCompanyForm.controls).forEach(control => {
      this.editCompanyForm.controls[control].markAsPristine();
      this.editCompanyForm.controls[control].markAsUntouched();
    });
  }

  async addAddress(){
    try {
      const result =  await this._viewAddressService.viewAddressModal(null, this.viewCompanyData.companyName, this.viewCompanyData.companyID);
      if(result){
        this.getAddressData();
      } else {
        this.getAddressData();
      }
    } catch (error) {
      
    }
   
  }

  submit(): void {
    // set some realistic defaults
    this.formError.submitting = true;
    this.formError.success = false;
    this.formError.error = false;
    if(this.viewCompanyData.companyID){
      this._companyService.updateCompany(this.viewCompanyData).subscribe(
        (response) => {
          this.formError.success = true;
          setTimeout(function() {
            this.formError.submitting = false;
            this.formError.success = false;
            this.resetForm();
          }.bind(this), 3000);
        }, 
        (err) => {
          console.log(err);
          this.formError.error = true;
          setTimeout(function() {
            this.formError.submitting = false;
          }.bind(this), 3000);
        }
      )
    } else {
      //new company
      this._companyService.newCompany(this.viewCompanyData).subscribe(
        (response) => {
          this.formError.success = true;
          this.companyID = response.companyID;
          setTimeout(function() {
            this.formError.submitting = false;
            this.formError.success = false;
            this.resetForm();
          }.bind(this), 3000);
        }, 
        (err) => {
          console.log(err);
          this.formError.error = true;
          setTimeout(function() {
            this.formError.submitting = false;
          }.bind(this), 3000);
        }
      )
    }
    
  }

  reset(form:NgForm){
    this.viewCompanyData = <Company> this.deepCopy(<Object>this.originalData);
    this.formError.submitting = false;
    this.formError.success = false;
    setTimeout(function() {
            this._markFormPristine();
    }.bind(this), 500);
  }



  async cancel(dirty) {
    if (dirty) {
      const result =  await this._modalConfirmationService.confirm('Still Editing the form?', 'You have not finished editing, are you sure you want to cancel?');
      if (result === 'confirm') {
        this.ref.close(false);
      } else if (result === 'cancel') {

      } else if (result === 'decline') {

      }
    } else {
      this.ref.close(false);
    }
  }

  rowDoubleClick(row){
    this._viewAddressService.viewAddressModal(row.data.addressID, this.viewCompanyData.companyName, this.viewCompanyData.companyID).then(
      (result) => {
        this.getAddressData();
      }, 
      (err) => {
        this.getAddressData();
      }
    );
  }
  public async rowActionClick(rowBtnClick) {
    if (rowBtnClick.button === 'view') {
      this._viewAddressService.viewAddressModal(rowBtnClick.rowData.addressID, this.viewCompanyData.companyName, this.viewCompanyData.companyID).then(
        (result) => {
          this.getAddressData();
        }, 
        (err) => {
          this.getAddressData();
        }
      );
    } else if (rowBtnClick.button === 'delete') {
      const result =  await this._modalConfirmationService.confirm(`Delete address?`, `Are you sure you want to delete this address?`);
      if (result === 'confirm') {
        this._addressService.deleteAddress(rowBtnClick.rowData.addressID).subscribe(
          (data) => {
            this.showToast("success","Address removed", `Address was removed sucesfully!`, 'checkmark-outline')
            this.getAddressData();
           },
          (err) => {
            this.showToast("danger","Removal failed", `Error occurred removing address!`, 'trash-2-outline')
            this.getAddressData();
          }
        );
      }
    }
  }
  
  async onChangeTab($event) {
    this.tabState =$event.tabTitle;
    if($event.tabTitle === 'Addresses'){
      await this.getAddressData();
    }
  }

  async getAddressData(){
    try {
      let data = <any> await this._addressService.getAddressesByCompanyID(this.companyID, false).toPromise();
      const _data = data.map(i => {
        i.addressString = `${i.line1 + ','} ${(i.line2 || '') === '' ? '': i.line2 + ','} ${i.city  + ','} ${i.postalCode}`;
        return i;
      });
      this.addresses = <PostalAddress[]>this.deepCopy(<Object>_data);
    } catch (error) {
      this.showToast("danger","Fetch Error", `Error occurred getting the company addresses!`, 'activity');
      this.ref.close(false);
    }
  }

  private showToast(type: NbComponentStatus, title: string, body: string, iconName: string = 'alert-triangle-outline') {
    const config = {
      status: type,
      destroyByClick: false,
      duration: 4000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: true,
      icon: iconName,
      pack: 'eva' 
    };

    this.toastrService.show(
      body,
      title,
      config
    );
  }

  displayFieldCss(form: NgForm, field: any) {
    if (form.dirty) {
      if (field.touched && field.invalid) {
        //invalid
        return 'danger';
      } else if (field.valid) {
        //valid
        return 'success';
      } else {
        //needs to be filled in
        return 'warning';
      }
    } else {
      return 'basic';
    }
  }

  deepCopy(obj): any {
    let copy;
    // Handle the 3 simple types, and null or undefined
    if (obj == undefined || typeof obj !== 'object') { return obj; }
    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());

        return copy;
    }
    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.deepCopy(obj[i]);
        }

        return copy;
    }
    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) { copy[attr] = this.deepCopy(<Object>obj[attr]); }
        }

        return copy;
    }
    throw new Error(`Unable to copy obj! Its type isn't supported.`);
  }
}

