import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ModalConfirmationService } from './confirmation-modal/confirmation-modal.service';
import { ModalConfirmationComponent } from './confirmation-modal/confirmation-modal.component';
import { InsurgoUIComponents } from '../../insurgo-ui-components/uiComponents.module';

import { ViewCompanyService } from './viewCompany/viewCompany.service';
import { ViewCompanyModalComponent } from './viewCompany/viewCompany.component';

import { ViewAddressService } from './viewAddress/viewAddress.service';
import { ViewAddressModalComponent } from './viewAddress/viewAddress.component';

import { AddUserService } from './addUser/addUser.service';
import { AddUserModalComponent } from './addUser/addUser.component';

import { BasicInputModalComponent } from './basic-input-modal/basic-input-modal.component';
import { GenericInputService } from './basic-input-modal/basic-input-modal.service';


import { MainPipeModule } from '../../insurgo-ui-components/pipes/pipes-module'
import { AgGridModule } from 'ag-grid-angular';



import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule, NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbUserModule,
  NbTabsetModule,
} from '@nebular/theme';

import { NgSelectModule } from '@ng-select/ng-select';

import { ThemeModule } from '../../@theme/theme.module';
import { SetTapeValueComponent } from './set-tape-value/set-tape-value.component';
import { ViewSingleTapeAnalysisService } from './view-single-tape-analysis/view-single-tape-analysis.service';
import { ViewSingleTapeAnalysisComponent } from './view-single-tape-analysis/view-single-tape-analysis.component';


@NgModule({
  imports: [
    FormsModule,
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule, 
    NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    NbTabsetModule,
    ThemeModule,
    NgSelectModule,
    MainPipeModule,
    AgGridModule.withComponents([]),
    CommonModule,
    InsurgoUIComponents,
  ],
  declarations: [
    ViewCompanyModalComponent,
    ViewAddressModalComponent,
    ModalConfirmationComponent,
    AddUserModalComponent,
    BasicInputModalComponent,
    SetTapeValueComponent,
    ViewSingleTapeAnalysisComponent
  ],
  entryComponents: [
    ViewCompanyModalComponent,
    ViewAddressModalComponent,
    ModalConfirmationComponent,
    AddUserModalComponent,
    BasicInputModalComponent
  ],
  providers: [
    ViewCompanyService,
    ModalConfirmationService,
    ViewAddressService,
    AddUserService,
    GenericInputService,
    ViewSingleTapeAnalysisService
  ]
})
export class ModalServiceModule {}