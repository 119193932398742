<nb-card accent="info">
    <nb-card-header class="clear-fix">
        Drive Management
        <button nbButton size="tiny" status="info" (click)=refreshDrives() class="float-right"><nb-icon icon="refresh-outline"></nb-icon></button>
    </nb-card-header>
    <nb-card-body class="text-center">
        <button nbButton nbButton size="small" class="mb-2" status="info" (click)=activateDrive() ><nb-icon icon="refresh-outline"></nb-icon>Activate Drive</button>
        <p class="d-block d-lg-none">This table is not optimised for your device, try horizontal scrolling</p>
        <ag-grid-angular
            #agGrid
            style="width: 100%; height: 500px;"
            class="ag-theme-alpine"
            [rowData]="driveData"
            [columnDefs]="columnDefs"
            [context]="context"
            [frameworkComponents]="frameworkComponents"
            [defaultColDef]="defaultColDef"
            [gridOptions]="gridOptions"
            [enableCellTextSelection]="true"
        >
        </ag-grid-angular>
    </nb-card-body>
</nb-card>