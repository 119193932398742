import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ViewCompanyModalComponent } from './viewCompany.component';

@Injectable()
export class ViewCompanyService {

    constructor(
      private dialogService: NbDialogService,
    ) {}

    viewCompanyModal(companyID?) {

        const modal = this.dialogService.open(ViewCompanyModalComponent, {
          context: {
              companyID: companyID
          },
        });

        return new Promise<Account>((resolve, reject) => {
          modal.onClose.subscribe((result) => {
              resolve(result);
          });
        });
      }
}