import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { BasicInputModalComponent } from './basic-input-modal.component';

@Injectable()
export class GenericInputService {

    constructor(
        private dialogService: NbDialogService
    ) {}

    show(titleMessage: string, bodyMessage: string, confirmButtonText: string, cancelButtonText: string, multiLine?: boolean): Promise<string> {
        let _confirmButton = confirmButtonText ? confirmButtonText : 'Yes';
        let _cancelButton = cancelButtonText;
        const modal = this.dialogService.open(BasicInputModalComponent, {
            closeOnBackdropClick: false,
            // dialogClass: "z-index:10000;",
            context: {
                title: titleMessage,
                bodyMessage: bodyMessage,  
                confirmButtonText: _confirmButton,
                cancelButtonText: _cancelButton,  
                multiLine: multiLine,
            },
          });

        return new Promise<string>((resolve, reject) => {
            modal.onClose.subscribe((result) =>{
                resolve(result);
            });
          });
    }
}