import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SecondsMinutesHoursPipe } from './seconds-minutes-hour.pipe';
import { TruncatePipe } from './text-pipes';
import { TimeAgoPipe } from './time-ago.pipe';
import { PluralPipe } from './plural.pipe';
import { HttpifyPipe } from './httpify.pipe';
@NgModule({
  imports: [CommonModule],
  declarations: [SecondsMinutesHoursPipe, TimeAgoPipe, TruncatePipe, PluralPipe, HttpifyPipe], // <---
  exports: [SecondsMinutesHoursPipe, TimeAgoPipe, TruncatePipe, PluralPipe, HttpifyPipe] // <---
})

export class MainPipeModule { }
