import { Component, OnInit } from '@angular/core';
import { ModalConfirmationService } from '../../../insurgo-services/modalTemplates/confirmation-modal/confirmation-modal.service';
import { NbToastrService, NbComponentStatus, NbGlobalPhysicalPosition } from '@nebular/theme';
import { UserService } from '../../../insurgo-services/user.service';
import { ButtonRendererComponent } from '../../../insurgo-services/cellRenderers/button.renderer.component';
import { AddUserService } from '../../../insurgo-services/modalTemplates/addUser/addUser.service';
import moment from 'moment';

@Component({
  selector: 'ngx-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  public columnDefs;
  public context;
  public frameworkComponents;
  public defaultColDef;
  public gridOptions;

  constructor(
    private _modalConfirmationService: ModalConfirmationService,
    private toastrService: NbToastrService,
    private _userService: UserService,
    private _addUserService: AddUserService
  ) {
    this.gridOptions = {
      onRowDoubleClicked: this.rowDoubleClick.bind(this)
    }
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };
    this.columnDefs = [
      { headerName: 'ID', field: 'id', flex: 1, minWidth: 120, tooltipField: "id" },
      { headerName: 'Name', field: 'name', flex: 2, minWidth: 120, tooltipField: "name", sortable: true, sort: 'asc', },
      { headerName: 'Email', field: 'email', flex: 3, minWidth: 120, tooltipField: "email" },
      { headerName: 'Type', field: 'userRole', flex: 2, minWidth: 120, valueFormatter: this.userTypeFormatter },
      { headerName: 'Verified', field: 'emailVerified', minWidth: 120, flex: 2, valueFormatter: this.verifiedFormatter },
      { headerName: 'Lock Status', field: 'accountLocked', minWidth: 120, flex: 2, valueFormatter: this.lockFormatter },
      { headerName: 'Joined Date', field: 'joinedDate', minWidth: 120, flex: 2, valueFormatter: this.dateFormatter },
      {
        headerName: 'Actions', minWidth: 200, filter: false, sortable: false,
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.showAccountModal.bind(this),
          buttons: [
            { id: 'view', label: '', class: 'primary', icon: 'eye-outline' }, 
            { id: 'lock', label: '', class: 'danger', icon: 'lock-outline', tooltip: 'lock account', show: this.buttonLockDecider }, 
            { id: 'unlock', label: '', class: 'success', icon: 'unlock-outline', tooltip: 'unlock account', show: this.buttonUnLockDecider},
            { id: 're-invite', label: '', class: 'success', icon: 'email-outline', tooltip: 'Send a new invite', show: this.reInviteDecider}
          ]
        }
      },
    ];
    this.context = { componentParent: this };
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      filter: true,
      resizable: true,
    };
  }

  userData: Object[];

  reInviteDecider(data){
    if(data.emailVerified === 0) return true;
    return false;

  }
  buttonUnLockDecider(data){
    if(data.accountLocked === 1) return true;
    return false;
  }
  buttonLockDecider(data){
    if(data.accountLocked === 0) return true;
    return false;
  }

  verifiedFormatter(params) {
    return (params.value === 1) ? 'Verified' : 'Unverified';
  }

  userTypeFormatter(params) {
    return (params.value === 9) ? 'Admin' : 'Standard';
  }

  dateFormatter(params) {
    let formattedDate = moment(params.value, 'x').format('DD/MM/YYYY');
    if (formattedDate === 'Invalid date') {
      formattedDate = '';
    }
    return formattedDate;
  }


  lockFormatter(params) {
    let returnValue = 'locked';
    if (params.value === '0' || params.value === 0) {
      returnValue = 'open';
    }

    return returnValue;
  }

  ngOnInit() {
    this.reloadUsers();
  }

  reloadUsers() {
    this._userService.getAllUsers().subscribe(
      (users) => {
        this.userData = users.map((i) => {
          i.name = `${i.firstName} ${i.lastName}`
          return i;
        });

      },
      (err) => {

      }
    )
  }

  private showToast(type: NbComponentStatus, title: string, body: string, iconName: string = 'alert-triangle-outline') {
    const config = {
      status: type,
      destroyByClick: false,
      duration: 4000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: true,
      icon: iconName,
      pack: 'eva'
    };

    this.toastrService.show(
      body,
      title,
      config
    );
  }

  addUser() {
    this._addUserService.addUserModal(null).then(
      (result) => {
        this.reloadUsers();
      },
      (err) => {
        this.reloadUsers();
      }
    );
  }

  rowDoubleClick(row) {
    this._addUserService.addUserModal(row.data.id).then(
      (result) => {
        this.reloadUsers();
      },
      (err) => {
        this.reloadUsers();
      }
    );
  }

  public async showAccountModal(rowBtnClick) {
    if (rowBtnClick.button === 'view') {
      this._addUserService.addUserModal(rowBtnClick.rowData.id).then(
        (result) => {
          this.reloadUsers();
        },
        (err) => {
          this.reloadUsers();
        }
      );
    } else if (rowBtnClick.button === 'lock') {
      if (rowBtnClick.rowData.accountLocked === '0' || rowBtnClick.rowData.accountLocked === 0) {
        const result = await this._modalConfirmationService.confirm(`${rowBtnClick.rowData.name}`, `Are you sure you want to lock this account?`);
        if (result === 'confirm') {
          this._userService.lockUser(rowBtnClick.rowData.id).subscribe(
            (data) => {
              this.showToast("success", "User locked", `${rowBtnClick.rowData.firstName} ${rowBtnClick.rowData.lastName} was locked successfully!`, 'checkmark-outline')
              this.reloadUsers();
            },
            (err) => {
              this.showToast("danger", "Locking failed", `Error occurred locking ${rowBtnClick.rowData.firstName} ${rowBtnClick.rowData.lastName}!`, 'alert-triangle-outline')
              this.reloadUsers();
            }
          );
        }
      } else {
        // account already locked
        this.showToast("warning", "Cannot lock", `Account already locked`, 'alert-triangle-outline')
      }
    } else if (rowBtnClick.button === 'unlock') {
      if (rowBtnClick.rowData.accountLocked === '1' || rowBtnClick.rowData.accountLocked === 1) {
        const result = await this._modalConfirmationService.confirm(`${rowBtnClick.rowData.name}`, `Are you sure you want to unlock this account?`);
        if (result === 'confirm') {
          this._userService.unlockUser(rowBtnClick.rowData.id).subscribe(
            (data) => {
              this.showToast("success", "User unlocked", `${rowBtnClick.rowData.firstName} ${rowBtnClick.rowData.lastName} was unlocked successfully!`, 'checkmark-outline')
              this.reloadUsers();
            },
            (err) => {
              this.showToast("danger", "Unlocking failed", `Error occurred unlocking ${rowBtnClick.rowData.firstName} ${rowBtnClick.rowData.lastName}!`, 'alert-triangle-outline')
              this.reloadUsers();
            }
          );
        }
      } else {
        // account already unlocked
        this.showToast("warning", "Cannot unlock", `Account already open`, 'alert-triangle-outline')
      }
    } else if (rowBtnClick.button === 're-invite'){
      const result = await this._modalConfirmationService.confirm(`${rowBtnClick.rowData.name}`, `Are you sure you want to re-invite this user?`);
        if (result === 'confirm') {
          this._userService.reInviteUser(rowBtnClick.rowData.id).subscribe(
            (data) => {
              this.showToast("success", "User Invited", `${rowBtnClick.rowData.firstName} ${rowBtnClick.rowData.lastName} was send a new invitation!`, 'checkmark-outline')
              this.reloadUsers();
            },
            (err) => {
              this.showToast("danger", "Invitation failed", `Error occurred re-inviting ${rowBtnClick.rowData.firstName} ${rowBtnClick.rowData.lastName}!`, 'alert-triangle-outline')
              this.reloadUsers();
            }
          );
        }
    }
  }
}
